import { PatientInput } from '../../components/forms/create-user-form/create-patient-form/patientFormTypes';
import {
  ClinicianInput,
  UserTypes,
} from '../../components/forms/create-user-form/CreateUserUtils';
import { UserInfoDto } from '../backendType';

export function toPatientInfoDto(
  userInput: PatientInput,
  emrPatientId?: string | undefined,
): UserInfoDto {
  const email = userInput.isTestUser
    ? `${userInput.firstName.trim().replace(/\s+/g, '.')}@lakecarmel.com`
    : userInput.email;
  const userInfoDto: UserInfoDto = {
    email,
    name: userInput.firstName,
    last_name: userInput.lastName,
    middle_name: userInput.middleName,
    role: UserTypes.Patient,
    additional_user_info: {
      height: userInput.height,
      is_test_user: userInput.isTestUser,
      date_of_birth: userInput.dateOfBirth,
    },
  };
  if (emrPatientId) {
    userInfoDto.emr_user_id = emrPatientId;
  }
  if (userInput.isTestUser && userInfoDto.additional_user_info) {
    userInfoDto.additional_user_info.weight = userInput.weight;
    userInfoDto.additional_user_info.gender = userInput.gender;
    userInfoDto.additional_user_info.surgery_date = userInput.surgeryDate;
    userInfoDto.additional_user_info.surgical_leg = userInput.surgicalLeg;
    userInfoDto.additional_user_info.medical_condition = userInput.medicalCondition;
    userInfoDto.additional_user_info.ethnicity = userInput.ethnicity;
  }
  return userInfoDto;
}

export function toClinicianInfoDto(userInput: ClinicianInput) {
  const userInfoDto: UserInfoDto = {
    email: userInput.email,
    role: UserTypes.Clinician,
    name: userInput.firstName,
    last_name: userInput.lastName,
    additional_user_info: {
      prefix: userInput.prefix,
      is_test_user: userInput.isTestUser,
    },
  };
  return userInfoDto;
}

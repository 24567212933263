/** @jsxImportSource @emotion/react */

import { useRef, useState } from 'react';
import { CSSObject } from '@emotion/react';
import { produce } from 'immer';

import Form from './Form';
import { TreatmentPlanInput } from './treatmentPlanTypes';
import TabsView, { Tab } from '../../../commons/TabsView';
import { card, shapes } from '../../../../style/shapes';
import ActivitySelection from './ActivitySelection';
import TemplateSelection from './TemplateSelection';
import {
  copyToActivitiesInput,
  createEmptyActivityInput,
} from './planCreationUtils';
import { fonts } from '../../../../style/fonts';
import { TreatmentPlanTemplate } from '../../../../models/TreatmentPlanTemplate';
import { ActivityDefinition } from '../../../../types/library';
import { colors } from '../../../../style/colors';
import { SetFormData } from '../../../commons/form/useForm';
import { breakpoints } from '../../../../style/breakpoints';

const headerCss: CSSObject = {
  padding: '24px 18px',
  '& > .tabs-container': {
    padding: 0,
    fontSize: fonts.h2?.fontSizeAdjust,
  },
  borderBottom: shapes.border,
};

const planCreationCss: CSSObject = {
  display: 'flex',
  position: 'relative',
  overflowX: 'auto',
  overflowY: 'hidden',
  height: 'inherit',
  gap: '1.25rem',

  '.selection-card, .form-card': {
    ...card,
  },

  '.templates': {
    [breakpoints.small]: {
      minWidth: '488px',
      maxWidth: '488px',
    },
    [breakpoints.medium]: {
      minWidth: '590px',
      maxWidth: '590px',
    },
    [breakpoints.large]: {
      minWidth: '945px',
      maxWidth: '945px',
    },
  },

  '.loader-background': { backgroundColor: `${colors.dividerGrey}80` },
};

const tabs: Tab[] = [
  {
    label: 'Activities',
    id: 'activities',
  },
  {
    label: 'Templates',
    id: 'templates',
  },
];

export default function PlanCreation() {
  const [selectedTab, setSelectedTab] = useState<string>('activities');
  const setFormDataRef = useRef<undefined | SetFormData<TreatmentPlanInput>>(
    undefined,
  );

  const onActivitySelect = (activityDefinition: ActivityDefinition) => {
    setFormDataRef.current?.(
      produce((draftFormData) => {
        const activityInput = createEmptyActivityInput(activityDefinition);
        draftFormData?.activities.push(activityInput);
      }),
    );
  };

  const onTemplateSelect = (template: TreatmentPlanTemplate) => {
    setFormDataRef.current?.(
      produce((draftFormData) => {
        if (!draftFormData) {
          return;
        }
        draftFormData.activities.push(
          ...copyToActivitiesInput(template.activities),
        );
        if (!draftFormData.description) {
          draftFormData.description = template.description;
        }
        if (!draftFormData.name) {
          draftFormData.name = template.name;
        }
      }),
    );
  };

  return (
    <div css={planCreationCss}>
      <div
        className={`selection-card ${selectedTab}`}
        css={{ display: 'flex', flexDirection: 'column', flex: '0 1 auto' }}
      >
        <div css={headerCss}>
          <TabsView
            tabs={tabs}
            onClick={(id) => setSelectedTab(id)}
            selectedId={selectedTab}
          />
        </div>
        <div
          css={{
            display: 'flex',
            height: '100%',
            paddingBottom: 100,
          }}
        >
          {selectedTab === 'activities' && (
            <ActivitySelection onSelect={onActivitySelect} />
          )}
          {selectedTab === 'templates' && (
            <TemplateSelection onSelect={onTemplateSelect} />
          )}
        </div>
      </div>
      <div className="form-card" css={{ flex: 1 }}>
        <Form setFormDataRef={setFormDataRef} />
      </div>
    </div>
  );
}

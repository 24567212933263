/** @jsxImportSource @emotion/react */

import { createSelector } from '@reduxjs/toolkit';
import { getUsersSelector } from '../../models/factories/userFactories';
import { User } from '../../models/User';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import TableView from '../commons/TableView';
import { createColumnHelper } from '@tanstack/react-table';
import EditIcon from '../../assets/edit.svg';
import ViewEyeIcon from '../../assets/viewEye.svg';
import { CSSObject } from '@emotion/react';
import { ClicableTableCellCss, getFormattedName } from './ManagementUtils';
import { FormMode } from '../commons/form/formUtils';
import CreateUserModal from '../forms/create-user-form/CreateUserModal';
import { Tooltip } from '@mui/material';
import { fonts } from '../../style/fonts';

const getCliniciansRowsData = createSelector(
  [getUsersSelector],
  (users): User[] => {
    return Object.values(users).filter((user) => user.isClinician());
  },
);

type CliniciansListProps = {
  nameSearchTerm: string | undefined;
  clinicianFormMode: FormMode<User> | undefined;
  setClinicianFormMode: (clinicianFormMode: FormMode<User> | undefined) => void;
  setNameSearchTerm: ((name: string) => void) | undefined;
};

const cliniciansTableCss: CSSObject = {
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: '100%',
  overflow: 'hidden',

  '.table-button': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },

  '.centrally-algned-header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

function getColumns(
  setClinicianFormMode: (clinicianFormMode: FormMode<User> | undefined) => void,
) {
  const columnHelper = createColumnHelper<User>();
  const columns = [
    columnHelper.accessor((row) => getFormattedName(row), {
      header: () => (
        <Tooltip title="Clinician Name">
          <span>Clinician Name</span>
        </Tooltip>
      ),
      id: 'clinicianName',
      meta: {
        width: '42%',
      },
      enableColumnFilter: false,
      enableSorting: true,
      sortingFn: 'alphanumeric',
      sortUndefined: false,
      cell: (cell) => {
        const cellValue = cell.getValue();
        return (
          <Tooltip title={cellValue}>
            <span css={{ ...ClicableTableCellCss, ...fonts.largeLabel }}>
              {cellValue}
            </span>
          </Tooltip>
        );
      },
    }),
    columnHelper.accessor((row) => row.email, {
      header: () => (
        <Tooltip title="Email">
          <span>Email</span>
        </Tooltip>
      ),
      id: 'email',
      meta: {
        width: '42%',
      },
      enableColumnFilter: false,
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (cell) => {
        const cellValue = cell.getValue();
        return (
          <Tooltip title={cellValue}>
            <span>{cellValue}</span>
          </Tooltip>
        );
      },
    }),
    columnHelper.accessor(() => '', {
      header: () => (
        <Tooltip title="Details">
          <span>Details</span>
        </Tooltip>
      ),
      id: 'details',
      meta: {
        width: '8%',
      },
      cell: (cell) => {
        return (
          <Tooltip title={'Details'}>
            <button
              type="button"
              className="table-button"
              onClick={(e) => {
                e.stopPropagation();
                setClinicianFormMode({
                  mode: 'preview',
                  initData: cell.row.original,
                });
              }}
            >
              <ViewEyeIcon height="24" width="24" />
            </button>
          </Tooltip>
        );
      },
      enableResizing: false,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor(() => '', {
      header: () => (
        <Tooltip title="Edit">
          <span>Edit</span>
        </Tooltip>
      ),
      id: 'edit',
      meta: {
        width: '8%',
      },
      cell: (cell) => {
        return (
          <Tooltip title={'Edit'}>
            <button
              type="button"
              className="table-button"
              onClick={(e) => {
                e.stopPropagation();
                setClinicianFormMode({
                  mode: 'edit',
                  initData: cell.row.original,
                });
              }}
            >
              <EditIcon height="21" width="21" />
            </button>
          </Tooltip>
        );
      },
      enableResizing: false,
      enableColumnFilter: false,
      enableSorting: false,
    }),
  ];
  return columns;
}

export default function CliniciansList({
  nameSearchTerm,
  clinicianFormMode,
  setClinicianFormMode,
  setNameSearchTerm,
}: CliniciansListProps) {
  const cliniciansRowsData = useSelector(getCliniciansRowsData);
  const columns = useMemo(() => getColumns(setClinicianFormMode), []);
  const initSorting = [{ id: 'Clinician Name', desc: false }];

  const TableViewWithType = TableView<User>;

  return (
    <div css={cliniciansTableCss}>
      <TableViewWithType
        tableId="CliniciansTable"
        data={cliniciansRowsData}
        columns={columns}
        globalFilter={nameSearchTerm}
        onRowClick={() => {}}
        getRowId={(data) => data.id}
        initSorting={initSorting}
        searchableFieldIds={['clinicianName', 'email']}
      />
      <CreateUserModal
        type="clinician"
        formMode={clinicianFormMode}
        setFormMode={setClinicianFormMode}
        onCreateUser={setNameSearchTerm}
      />
    </div>
  );
}

/** @jsxImportSource @emotion/react */

import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { Tooltip } from '@mui/material';

import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import EditIcon from '../../../assets/edit.svg';
import { User } from '../../../models/User';
import TableView from '../../commons/TableView';
import { fonts } from '../../../style/fonts';
import { ClicableTableCellCss, getFormattedName } from '../ManagementUtils';
import { dateSortingFn } from '../../../utils/dateUtils';

function getColumns({
  onViewPatientDetailsClick,
  onEditPatientDetailsClick,
}: {
  onViewPatientDetailsClick: (patient: User) => void;
  onEditPatientDetailsClick: (patient: User) => void;
}) {
  const columnHelper = createColumnHelper<User>();
  const columns = [
    columnHelper.accessor((row) => getFormattedName(row), {
      header: () => (
        <Tooltip title="Patient Name">
          <span>Patient Name</span>
        </Tooltip>
      ),
      id: 'patientName',
      meta: {
        width: '23%',
      },
      enableColumnFilter: false,
      enableSorting: true,
      sortingFn: 'alphanumeric',
      sortUndefined: false,
      cell: (cell) => {
        const userId = cell.row.original.id;
        const cellValue = cell.getValue();
        return (
          <Tooltip title={cellValue}>
            <a
              href={`${window.location.origin}/patient?patient-id=${userId}`}
              onClick={(event) => {
                if (event.button === 0) {
                  // 0 is the left mouse button
                  event.preventDefault();
                }
              }}
              css={{ ...ClicableTableCellCss, ...fonts.largeLabel }}
            >
              {cell.getValue()}
            </a>
          </Tooltip>
        );
      },
    }),
    columnHelper.accessor((row) => row.emrUserId, {
      header: () => (
        <Tooltip title="EMR Patient ID">
          <span>EMR Patient ID</span>
        </Tooltip>
      ),
      id: 'emrId',
      meta: {
        width: '13%',
      },
      enableColumnFilter: false,
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (cell) => {
        const cellValue = cell.getValue();
        return (
          <Tooltip title={cellValue}>
            <span>{cell.getValue()}</span>
          </Tooltip>
        );
      },
    }),
    columnHelper.accessor((row) => row.dateOfBirth, {
      header: () => (
        <Tooltip title="Date of Birth">
          <span>Date of Birth</span>
        </Tooltip>
      ),
      id: 'dob',
      meta: {
        width: '13%',
      },
      cell: (cell) => {
        const cellValue = cell.getValue();
        const formattedCellValue =
          cellValue && format(parseISO(cellValue), 'dd/MM/yyyy');
        if (formattedCellValue) {
          return (
            <Tooltip title={formattedCellValue}>
              <span>{formattedCellValue}</span>
            </Tooltip>
          );
        }
      },
      enableColumnFilter: false,
      enableSorting: true,
      sortingFn: dateSortingFn,
    }),
    columnHelper.accessor((row) => row.email, {
      header: () => (
        <Tooltip title="Email">
          <span>Email</span>
        </Tooltip>
      ),
      id: 'email',
      meta: {
        width: '22%',
      },
      enableColumnFilter: false,
      enableSorting: true,
      sortingFn: 'alphanumeric',
    }),
    columnHelper.accessor((row) => row.creationDate, {
      header: () => (
        <Tooltip title="Registration Date">
          <span>Registration Date</span>
        </Tooltip>
      ),
      id: 'registrationDate',
      meta: {
        width: '13%',
      },
      cell: (cell) => {
        const cellValue = cell.getValue().toLocaleDateString();
        return (
          <Tooltip title={cellValue}>
            <span>{cellValue}</span>
          </Tooltip>
        );
      },
      enableColumnFilter: false,
      enableSorting: true,
      sortingFn: dateSortingFn,
    }),
    columnHelper.accessor(() => '', {
      header: () => (
        <Tooltip title="Details">
          <span>Details</span>
        </Tooltip>
      ),
      id: 'details',
      meta: {
        width: '8%',
      },
      cell: (cell) => {
        return (
          <Tooltip title={'Details'}>
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onViewPatientDetailsClick(cell.row.original);
              }}
            >
              <PersonSearchIcon />
            </button>
          </Tooltip>
        );
      },
      enableResizing: false,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor(() => '', {
      header: () => (
        <Tooltip title="Edit">
          <span>Edit</span>
        </Tooltip>
      ),
      id: 'edit',
      meta: {
        width: '8%',
      },
      cell: (cell) => {
        return (
          <Tooltip title={'Details'}>
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onEditPatientDetailsClick(cell.row.original);
              }}
            >
              <EditIcon height="20" width="20" />
            </button>
          </Tooltip>
        );
      },
      enableResizing: false,
      enableColumnFilter: false,
      enableSorting: false,
    }),
  ];
  return columns;
}

const TableViewWithType = TableView<User>;

export default function PatientsTableView({
  patientsRowsData,
  onRowClick,
  nameSearchTerm,
  // loading,
  onViewPatientDetailsClick,
  onEditPatientDetailsClick,
}: {
  patientsRowsData: User[];
  onRowClick: (data: User) => void;
  nameSearchTerm?: string;
  // loading: boolean;
  onViewPatientDetailsClick: (patient: User) => void;
  onEditPatientDetailsClick: (patient: User) => void;
}) {
  const columns = useMemo(
    () => getColumns({ onViewPatientDetailsClick, onEditPatientDetailsClick }),
    [],
  );

  const initSorting = [
    { id: 'Patient Name', desc: false },
    { id: 'EMR Patient ID', desc: false },
    { id: 'DOB', desc: false },
    { id: 'Email', desc: false },
    { id: 'Registration Date', desc: false },
  ];

  return (
    <TableViewWithType
      tableId="PatientsTable"
      data={patientsRowsData}
      columns={columns}
      globalFilter={nameSearchTerm}
      // setGlobalFilter={setGlobalFilter}
      onRowClick={onRowClick}
      getRowId={(data) => data.id}
      initSorting={initSorting}
      searchableFieldIds={['patientName', 'email']}
    />
  );
}

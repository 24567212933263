/** @jsxImportSource @emotion/react */
import { ChangeEvent } from 'react';
import { set, get } from 'lodash-es';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

import {
  CheckboxGroupPropsType,
  InputFieldPropsType,
  OptionObj,
  SimpleCheckboxGroup,
  SimpleInputField,
  SimpleRadioInputField,
  SimpleSelectInputField,
  createClassName,
} from './basic';
import { Path } from '../../../utils/typeScriptUtils';
import EditableInput from './EditableInput';
import { CSSObject } from '@emotion/react';

export type InputState<T, E> = {
  formData: T;
  setFormData?: (formData: T) => void;
  onChange?: (value: any, pathName: Path<T>) => void;
  formErrors: E | undefined;
  name: Path<T>;
};

function basic<T extends {}, E extends {}>(props: InputState<T, E>) {
  const { name: pathName, formData, formErrors } = props;

  const name = pathName as string;

  return {
    value: get(formData, name) ?? '',
    onChange: (newValue: any) => {
      if (props.onChange) {
        props.onChange(newValue, pathName);
      } else if (props.setFormData) {
        const newFormData = { ...formData };
        set(newFormData, name, newValue);
        props.setFormData(newFormData);
      } else {
        throw new Error('must provide setFormData or onChange');
      }
    },
    error: get(formErrors, name),
  };
}

export function InputField<T extends {}, E extends {}>(
  props: InputFieldPropsType & {
    textarea?: boolean;
    additionalLabelElements?: EmotionJSX.Element;
    autoComplete?: string;
    tooltipLabelText?: string;
    placeholder?: string;
    style?: CSSObject;
  } & InputState<T, E>,
) {
  return <SimpleInputField {...props} {...basic(props)} />;
}

export function EditableInputField<T extends {}, E extends {}>(
  props: InputFieldPropsType & InputState<T, E>,
) {
  const { setFormData, formData, formErrors, ...inputProps } = props;
  return <EditableInput {...inputProps} {...basic(props)} />;
}

export type CheckboxPropsType<T, E> = {
  disabled?: boolean;
  className?: string;
  name: Path<T>;
  label: string;
  formData: T;
  formErrors: E | undefined;
  setFormData?: (formData: T) => void;
  onChange?: (value: any, pathName: Path<T>) => void;
};

export function CheckboxField<T extends {}, E extends {}>(
  props: CheckboxPropsType<T, E>,
) {
  const { disabled, label, className, name, formData } = props;
  const checked: boolean = get(formData, name as string);
  return (
    <label className={`checkbox-label ${name} ${className}`}>
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const checkboxValue = event.target.checked;
          if (props.onChange) {
            props.onChange(checkboxValue, name);
          } else if (props.setFormData) {
            const newFormData = { ...formData };
            set(newFormData, name, checkboxValue);
            props.setFormData(newFormData);
          } else {
            throw new Error('must provide setFormData or onChange');
          }
        }}
      />
      {label}
    </label>
  );
}

export type RadioInputFieldOption = { value: string; tooltip?: string };
export function RadioInputField<T extends {}, E extends {}>(
  props: InputFieldPropsType & {
    options: readonly RadioInputFieldOption[];
  } & InputState<T, E>,
) {
  return <SimpleRadioInputField {...props} {...basic(props)} />;
}

export function SelectInputField<T extends {}, E extends {}>(
  props: InputFieldPropsType & {
    options: readonly string[] | readonly OptionObj[];
  } & InputState<T, E>,
) {
  return <SimpleSelectInputField {...props} {...basic(props)} />;
}

export function CheckboxGroup<T extends {}, E extends {}>(
  props: CheckboxGroupPropsType & InputState<T, E>,
) {
  const { legend } = props;

  return (
    <div
      className={createClassName({
        innerClassName: 'checkbox-field-grouping field-grouping',
        ...props,
      })}
    >
      <legend>{legend}</legend>
      <SimpleCheckboxGroup {...props} {...basic(props)} />
    </div>
  );
}

import { CSSObject } from '@emotion/react';
import { User } from '../../models/User';
import { isEmpty } from '../../state-manager/utils/compare';
import { colors } from '../../style/colors';

export function getFormattedName(user: User) {
  if (!isEmpty(user.firstName) && !isEmpty(user.lastName)) {
    return `${user.lastName}, ${user.firstName}`;
  }
  if (!isEmpty(user.firstName)) {
    return user.firstName;
  }
  return user.lastName;
}

export const ClicableTableCellCss: CSSObject = {
  color: colors.basicBlue,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
    color: colors.basicBlue,
  },
};

import {
  MovementInputDto,
  TreatmentPlanInputDto,
  TreatmentPlanTemplateInputDto,
} from '../backendType';
import {
  ActivityInput,
  TreatmentPlanInput,
} from '../../components/patient-details/treatment-plans/creation/treatmentPlanTypes';
import { ActivityDefinition, ActivityDefinitionId } from '../library';
import { getRandomItem } from '../../utils/arrayUtils';
import { isEmpty } from '../../state-manager/utils/compare';
import { addTime } from '../../utils/dateUtils';
import { parseISO } from 'date-fns';

function toMovementInput(
  activity: ActivityInput,
  activitiesDefinitions: Record<ActivityDefinitionId, ActivityDefinition>,
  isTemplate: boolean,
): MovementInputDto {
  const activityDefinition = activitiesDefinitions[activity.activityDefinitionId];
  if (!activityDefinition) {
    throw new Error('missing activityDefinition for: ' + activity.activityDefinitionId);
  }
  const { settings } = activityDefinition;
  const movementInputDto: MovementInputDto = {
    movement_id: activityDefinition.id,
    movement_info: {},
  };
  if (settings.type) {
    if (activity.type) {
      movementInputDto.movement_info.type = activity.type;
    } else if (!isTemplate) {
      throw new Error('type in missing');
    }
  }
  if (activity.side) {
    movementInputDto.movement_info.side = activity.side;
  } else if (!isTemplate) {
    throw new Error('side in missing');
  }
  if (settings.rest) {
    if (!isEmpty(activity.rest)) {
      movementInputDto.movement_info.rest = activity.rest;
    } else if (!isTemplate) {
      throw new Error('rest in missing');
    }
  }
  if (settings.reps) {
    if (!isEmpty(activity.reps)) {
      movementInputDto.movement_info.reps = activity.reps;
    } else if (!isTemplate) {
      throw new Error('reps in missing');
    }
  }
  if (activity.minimumToConsiderRep) {
    if (!isEmpty(activity.minimumToConsiderRep)) {
      movementInputDto.movement_info.minimumToConsiderRep = activity.minimumToConsiderRep;
    } else if (!isTemplate) {
      throw new Error('reps threshold in missing');
    }
  }
  if (!isEmpty(activity.init_angle_degrees)) {
    movementInputDto.movement_info.init_angle_degrees = activity.init_angle_degrees;
  }
  if (settings.hold) {
    if (!isEmpty(activity.hold)) {
      movementInputDto.movement_info.hold = activity.hold;
    } else if (!isTemplate) {
      throw new Error('hold in missing');
    }
  }
  if (settings.sets) {
    if (!isEmpty(activity.sets)) {
      movementInputDto.movement_info.sets = activity.sets;
    } else if (!isTemplate) {
      throw new Error('sets in missing');
    }
  }
  if (settings.distance) {
    if (!isEmpty(activity.distance)) {
      movementInputDto.movement_info.distance = activity.distance;
    } else if (!isTemplate) {
      throw new Error('distance in missing');
    }
  }
  if (settings.duration) {
    if (!isEmpty(activity.duration)) {
      movementInputDto.movement_info.duration = activity.duration;
    } else if (!isTemplate) {
      throw new Error('duration in missing');
    }
  }
  if (activity.equipment) {
    movementInputDto.movement_info.equipment = activity.equipment;
  }

  if (!isEmpty(activity.comments)) {
    movementInputDto.movement_info.comments = activity.comments;
    movementInputDto.movement_info.require_patient_acknowledgment =
      activity.require_patient_acknowledgment;
  }

  movementInputDto.movement_info.goals = Object.entries(activity.goals)
    .filter(([metric_name]) => !metric_name.startsWith('volume'))
    .map(([metric_name, value]) => {
      return { metric_name, value };
    });

  if (activityDefinition.non_algo_pem && activity.sets && activity.reps) {
    const activityIncludeBand = activity.equipment?.find((item) => item.Name === 'Band');
    const activityIncludeWeights = activity.equipment?.find((item) => item.Units === 'Kg');
    const volumeMetrics = activityDefinition.non_algo_pem;
    const weightsMetric = volumeMetrics.find(
      (metric) => metric.metric_name == 'volume_weights',
    );
    const bandMetric = volumeMetrics.find((metric) => metric.metric_name == 'volume_band');
    const bodyMetric = volumeMetrics.find((metric) => metric.metric_name == 'volume_body');
    if (activityIncludeWeights && weightsMetric) {
      const totalWeights = activity.equipment
        ?.filter((item) => item.Units === 'Kg')
        .reduce((sum, item) => sum + (Number(item.Value) || 0), 0);
      if (totalWeights) {
        movementInputDto.movement_info.goals.push({
          metric_name: weightsMetric.metric_name,
          value: totalWeights * activity.sets * activity.reps,
        });
      }
    } else if (bandMetric && activityIncludeBand) {
      movementInputDto.movement_info.goals.push({
        metric_name: bandMetric.metric_name,
        value: activity.sets * activity.reps,
      });
    } else {
      if (bodyMetric) {
        movementInputDto.movement_info.goals.push({
          metric_name: bodyMetric.metric_name,
          value: activity.sets * activity.reps,
        });
      }
    }
  }
  return movementInputDto;
}

function fullfilMissingOrganSide(
  origActivities: ActivityInput[],
  activitiesDefinitions: Record<ActivityDefinitionId, ActivityDefinition>,
) {
  const activities = [...origActivities];
  activities.forEach((activity, index) => {
    const activityDefinition = activitiesDefinitions[activity.activityDefinitionId];

    if (!activityDefinition?.settings.side) {
      activities[index] = { ...activity, side: 'NA' };
      return;
    }
    if (activities[index]?.side) {
      return;
    }
    const previousActivity = activities[index - 1];
    const nextActivity = activities[index + 1];

    if (previousActivity && !activities[index]?.side) {
      const activityDefinitionForPreviousActivity =
        activitiesDefinitions[previousActivity.activityDefinitionId];
      if (
        previousActivity.side &&
        activityDefinitionForPreviousActivity?.position === activityDefinition?.position
      ) {
        activities[index] = { ...activity, side: previousActivity.side };
      }
    }
    if (nextActivity && !activities[index]?.side) {
      const activityDefinitionForNextActivity =
        activitiesDefinitions[nextActivity.activityDefinitionId];
      if (
        nextActivity.side &&
        activityDefinitionForNextActivity?.position === activityDefinition?.position
      ) {
        activities[index] = { ...activity, side: nextActivity.side };
      }
    }
    if (previousActivity && !activities[index]?.side) {
      if (previousActivity.side) {
        activities[index] = { ...activity, side: previousActivity.side };
      }
    }
    if (nextActivity && !activities[index]?.side) {
      if (nextActivity.side) {
        activities[index] = { ...activity, side: nextActivity.side };
      }
    }
    if (!activities[index]?.side || activities[index]?.side === 'NA') {
      const sideOptions = ['Left', 'Right'];
      const side = getRandomItem(sideOptions);
      activities[index] = { ...activity, side };
    }
  });
  return activities;
}

export function toTreatmentPlanInputDto({
  assignorId,
  treatmentPlanForm,
  activitiesDefinitions,
  caseId,
}: {
  assignorId: string;
  treatmentPlanForm: TreatmentPlanInput;
  activitiesDefinitions: Record<ActivityDefinitionId, ActivityDefinition>;
  caseId: string;
}): TreatmentPlanInputDto {
  const fixedActivities = fullfilMissingOrganSide(
    treatmentPlanForm.activities,
    activitiesDefinitions,
  );

  const startDate = addTime(treatmentPlanForm.startDate!, new Date());

  const treatmentPlanInputDto: TreatmentPlanInputDto = {
    name: treatmentPlanForm.name!,
    assignor_user_id: assignorId,
    start_date: parseISO(startDate).toISOString(),
    description: treatmentPlanForm.description || '',
    movements: fixedActivities.map((activity, index) => {
      return {
        plan_index: index + 1,
        ...toMovementInput(activity, activitiesDefinitions, false),
      };
    }),
    comments: [],
    state: '',
    case_id: caseId,
  };
  return treatmentPlanInputDto;
}

export function toTreatmentPlanTemplateInputDto(
  treatmentPlanForm: TreatmentPlanInput,
  activitiesDefinitions: Record<ActivityDefinitionId, ActivityDefinition>,
): TreatmentPlanTemplateInputDto {
  const treatmentPlanTemplateInputDto: TreatmentPlanTemplateInputDto = {
    name: treatmentPlanForm.name!,
    description: treatmentPlanForm.description || '',
    movements: treatmentPlanForm.activities.map((activity, index) => {
      return {
        plan_index: index + 1,
        ...toMovementInput(activity, activitiesDefinitions, true),
      };
    }),
    state: '',
  };
  return treatmentPlanTemplateInputDto;
}

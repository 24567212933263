import { useEffect, useMemo, useState } from 'react';
import { set } from 'lodash-es';

import {
  ActivityDefinition,
  ActivityDefinitionId,
} from '../../../../types/library';
import {
  DONT_CARE,
  NumericSetting,
  NumericOptionsSetting,
  ImprovementDirections,
} from '../../../../types/backendType';
import { useSelector } from 'react-redux';
import { getActivityDefinitionsSelector } from '../../../../state-manager/selectors/librarySelectors';
import {
  TreatmentPlanTemplate,
  TreatmentPlanTemplateId,
} from '../../../../models/TreatmentPlanTemplate';
import { TreatmentPlan } from '../../../../models/TreatmentPlan';
import {
  ActivityInput,
  TreatmentPlanInput,
} from '../creation/treatmentPlanTypes';
import { getTreatmentPlanTemplatesSelector } from '../../../../models/factories/treatmentPlanTemplateFactories';
import { getPatientTreatmentPlansSelector } from '../../../../models/factories/treatmentPlanFactories';
import { FormErrors } from '../../../commons/form/useForm';
import { isEmpty } from '../../../../state-manager/utils/compare';
import { isLoadInput } from '../TreatmentPlansUtils';
import { getPostMetrics } from '../../../../models/PostMetric';
import { getPatientActivitiesExecutionsSelector } from '../../../../models/ActivityExecution';

const REQUIRED = 'Required';

type ValidateActivityProp = {
  activity: ActivityInput;
  activityDefinition: ActivityDefinition;
  formErrors: FormErrors<TreatmentPlanInput>;
  index: number;
};

export type ValidateAs = 'template' | 'plan';

export type Mode = 'preview' | 'edit' | 'new';

export class TreatmentPlanFormValidation {
  private validateAs: ValidateAs;

  private mode: Mode;

  private activitiesDefinitions:
    | Record<ActivityDefinitionId, ActivityDefinition>
    | undefined;

  private treatmentPlanTemplates:
    | Record<TreatmentPlanTemplateId, TreatmentPlanTemplate>
    | undefined;

  private treatmentPlans: TreatmentPlan[] | undefined;

  private wasLastTreatmentPlanExecuted: boolean | undefined;

  private origin: TreatmentPlanInput | undefined;

  private onValidationDataChangedCallback: () => void;

  onValidationDataChanged(callback: () => void) {
    this.onValidationDataChangedCallback = callback;
  }

  private validationDataChanged() {
    this.onValidationDataChangedCallback();
  }

  constructor(mode: Mode, validateAs: ValidateAs) {
    this.mode = mode;
    this.validateAs = validateAs;
  }

  setActivitiesDefinitions(
    activitiesDefinitions: Record<ActivityDefinitionId, ActivityDefinition>,
  ) {
    this.activitiesDefinitions = activitiesDefinitions;
    this.validationDataChanged();
  }

  setTreatmentPlanTemplates(
    treatmentPlanTemplates: Record<
      TreatmentPlanTemplateId,
      TreatmentPlanTemplate
    >,
  ) {
    this.treatmentPlanTemplates = treatmentPlanTemplates;
    this.validationDataChanged();
  }

  setTreatmentPlans(treatmentPlans: TreatmentPlan[] | undefined) {
    this.treatmentPlans = treatmentPlans;
    this.validationDataChanged();
  }

  setWasLastTreatmentPlanExecuted(wasLastTpExecuted: boolean | undefined) {
    this.wasLastTreatmentPlanExecuted = wasLastTpExecuted;
    this.validationDataChanged();
  }

  setValidateAs(validateAs: ValidateAs) {
    this.validateAs = validateAs;
    this.validationDataChanged();
  }

  setOrigin(origin?: TreatmentPlanInput) {
    this.origin = origin;
    this.validationDataChanged();
  }

  getOrigin() {
    return this.origin;
  }

  setMode(mode: Mode) {
    this.mode = mode;
    this.validationDataChanged();
  }

  private validateName(
    name: string | undefined,
    id: string | undefined,
    formErrors: FormErrors<TreatmentPlanInput>,
    wasLastTreatmentPlanExecuted: boolean | undefined,
  ) {
    if (!name) {
      set(formErrors, 'name', REQUIRED);
    }
    let list: TreatmentPlan[] | TreatmentPlanTemplate[] | undefined;
    if (this.validateAs === 'plan') {
      list = this.treatmentPlans;
    } else if (this.validateAs === 'template' && this.treatmentPlanTemplates) {
      list = Object.values(this.treatmentPlanTemplates);
    }
    if (this.mode === 'edit' && this.origin?.name === name) {
      if (this.validateAs === 'plan') {
        const doesLastTpHaveTheSameName =
          this.treatmentPlans?.[0]?.name === this.origin?.name;

        if (doesLastTpHaveTheSameName && !wasLastTreatmentPlanExecuted) {
          return;
        }
      } else {
        return;
      }
    }

    const nameAlreadyExists = list?.find(
      (treatmentPlan: TreatmentPlan | TreatmentPlanTemplate) =>
        treatmentPlan.name == name,
    );
    if (nameAlreadyExists) {
      const message =
        this.validateAs === 'template'
          ? 'Template name already exists'
          : 'Name already exists';
      set(formErrors, 'name', message);
    }
  }

  private validateActivityReps({
    activity,
    activityDefinition,
    formErrors,
    index,
  }: ValidateActivityProp) {
    this.validateSetting({
      setting: activityDefinition.settings.reps,
      path: `activities.${index}.reps`,
      formErrors,
      value: activity.reps,
    });
  }

  private validateActivityDistance({
    activity,
    activityDefinition,
    formErrors,
    index,
  }: ValidateActivityProp) {
    this.validateSetting({
      setting: activityDefinition.settings.distance,
      path: `activities.${index}.distance`,
      formErrors,
      value: activity.distance,
    });
  }

  private validateRepOnMetricGoal({
    activity,
    activityDefinition,
  }: ValidateActivityProp) {
    if (
      !activityDefinition.repOn?.length ||
      isEmpty(activity.minimumToConsiderRep) ||
      !activityDefinition.settings.initial_angle ||
      isEmpty(activity.init_angle_degrees)
    ) {
      return;
    }
    const repOnMetricName = activityDefinition.repOn[1];
    const repOnPostMetric = activityDefinition.postExerciseMetric?.find(
      (metric) => {
        return metric.metric_name === repOnMetricName;
      },
    );
    const repOnMetricImprovementDirection =
      repOnPostMetric?.improvement_direction;
    const repOnMetricGoal = repOnMetricName && activity.goals[repOnMetricName];
    const repOnMinClinicalSignificance = repOnPostMetric?.metric_mcs;

    if (repOnMetricGoal) {
      const sumValue =
        activity.minimumToConsiderRep + activity.init_angle_degrees;
      const differenceValue =
        activity.init_angle_degrees - activity.minimumToConsiderRep;

      if (
        repOnMetricImprovementDirection === ImprovementDirections.Up &&
        sumValue > repOnMetricGoal
      ) {
        return `Goal value must be equal to or more than 
        the sum of Repetition Threshold and Initial Angle of ${sumValue}.`;
      }
      if (
        repOnMetricImprovementDirection === ImprovementDirections.Down &&
        differenceValue < repOnMetricGoal
      ) {
        return `Goal value must be equal to or less than 
        the difference of Initial Angle and Repetition Threshold of ${differenceValue}.`;
      }
      if (
        repOnMetricImprovementDirection === ImprovementDirections.Goal &&
        (repOnMetricGoal < sumValue - 2 * (repOnMinClinicalSignificance ?? 0) ||
          repOnMetricGoal > sumValue + 2 * (repOnMinClinicalSignificance ?? 0))
      ) {
        return `Goal value must be equal to the sum of Repetition Threshold 
        and Initial Angle +/- 2 times the minimum clinical significance.`;
      }
    }
  }

  private validateActivityMinimumToConsiderRep({
    activity,
    activityDefinition,
    formErrors,
    index,
  }: ValidateActivityProp) {
    const setting = activityDefinition.settings.minimum_to_consider_rep;
    const path = `activities.${index}.minimumToConsiderRep`;
    const value = activity.minimumToConsiderRep;

    if (!setting || !activityDefinition.repOn?.length) {
      return;
    }

    if (this.validateAs !== 'template' && isEmpty(value)) {
      set(formErrors, path, REQUIRED);
      return;
    }

    if (!isEmpty(value)) {
      if (value < Number(setting.options[1])) {
        set(formErrors, path, `Min is ${setting.options[1]}`);
        return;
      }
      if (value > Number(setting.options[2])) {
        set(formErrors, path, `Max is ${setting.options[2]}`);
        return;
      }
      const repOnMetricGoalError = this.validateRepOnMetricGoal({
        activity,
        activityDefinition,
        formErrors,
        index,
      });
      if (repOnMetricGoalError) {
        set(formErrors, path, repOnMetricGoalError);
        return;
      }
    }
  }

  private validateActivityInitialAngle({
    activity,
    activityDefinition,
    formErrors,
    index,
  }: ValidateActivityProp) {
    this.validateSetting({
      setting: activityDefinition.settings.initial_angle,
      path: `activities.${index}.init_angle_degrees`,
      formErrors,
      value: activity.init_angle_degrees,
    });
  }

  private validateActivitySets({
    activity,
    activityDefinition,
    formErrors,
    index,
  }: ValidateActivityProp) {
    this.validateSetting({
      setting: activityDefinition.settings.sets,
      path: `activities.${index}.sets`,
      formErrors,
      value: activity.sets,
    });
  }

  private validateActivityRest({
    activity,
    activityDefinition,
    formErrors,
    index,
  }: ValidateActivityProp) {
    this.validateSetting({
      setting: activityDefinition.settings.rest,
      path: `activities.${index}.rest`,
      formErrors,
      value: activity.rest,
    });
  }

  private validateSetting({
    setting,
    path,
    value,
    formErrors,
  }: {
    setting: NumericSetting | NumericOptionsSetting | undefined;
    path: string;
    value: number | undefined | null;
    formErrors: FormErrors<TreatmentPlanInput>;
  }) {
    if (!setting) {
      return;
    }
    if (this.validateAs !== 'template' && isEmpty(value)) {
      set(formErrors, path, REQUIRED);
    }
    let min: undefined | number = undefined;
    let max: undefined | number = undefined;
    let stepSize: undefined | number = undefined;

    if ('options' in setting) {
      min = Number(setting.options[1]);
      max = Number(setting.options[2]);
    } else {
      min = setting.min;
      max = setting.max;
      stepSize = setting.step_size;
    }
    if (min !== undefined && !isEmpty(value) && value < min) {
      set(formErrors, path, `Min is ${min}`);
      return;
    }
    if (max !== undefined && !isEmpty(value) && value > max) {
      set(formErrors, path, `Max is ${max}`);
      return;
    }
    if (stepSize && !isEmpty(value) && value % stepSize !== 0) {
      set(formErrors, path, `step is ${stepSize}`);
      return;
    }
  }

  private validateActivityHold({
    activity,
    activityDefinition,
    formErrors,
    index,
  }: ValidateActivityProp) {
    this.validateSetting({
      setting: activityDefinition.settings.hold,
      path: `activities.${index}.hold`,
      formErrors,
      value: activity.hold,
    });
  }

  private validateActivityDuration({
    activity,
    activityDefinition,
    formErrors,
    index,
  }: ValidateActivityProp) {
    this.validateSetting({
      setting: activityDefinition.settings.duration,
      path: `activities.${index}.duration`,
      formErrors,
      value: activity.duration,
    });
  }

  private validateActivitySide({
    activity,
    activityDefinition,
    formErrors,
    index,
  }: ValidateActivityProp) {
    if (
      this.validateAs !== 'template' &&
      activityDefinition.settings.side &&
      !activityDefinition.settings.side.options.includes(DONT_CARE) &&
      isEmpty(activity.side)
    ) {
      set(formErrors, `activities.${index}.side`, REQUIRED);
      return;
    }
  }

  private validateActivityCategory({
    activity,
    formErrors,
    index,
  }: ValidateActivityProp) {
    if (this.validateAs === 'template') {
      return;
    }
    if (!activity.type || activity.type.length < 1) {
      set(formErrors, `activities.${index}.type`, 'Select at least one option');
    }
  }

  private validateActivityEquipment({
    activity,
    formErrors,
    index,
  }: ValidateActivityProp) {
    if (this.validateAs === 'template') {
      return;
    }
    if (activity.equipment?.length) {
      activity.equipment.map((device, deviceIndex) => {
        if (isLoadInput(device)) {
          if (Number(device.Value) < Number(device.Min)) {
            set(
              formErrors,
              `activities.${index}.equipment.${deviceIndex}.Value`,
              `Min is ${device.Min}`,
            );
            return;
          }
          if (Number(device.Value) > Number(device.Max)) {
            set(
              formErrors,
              `activities.${index}.equipment.${deviceIndex}.Value`,
              `Max is ${device.Max}`,
            );
            return;
          }
        }

        if (device.Options && isEmpty(device.Value)) {
          set(
            formErrors,
            `activities.${index}.equipment.${deviceIndex}.Value`,
            'Select at least one option',
          );
          return;
        }
      });
    }
  }

  private validateActivityGoals({
    activity,
    formErrors,
    index,
    activityDefinition,
  }: ValidateActivityProp) {
    if (this.validateAs === 'template') {
      return;
    }
    const goals = activity.goals;
    const goalsErrors: Record<string, string> = {};
    const postMetrics = getPostMetrics(activityDefinition);
    if (!postMetrics) {
      return;
    }
    Object.entries(goals).forEach(([key, value]) => {
      const postMetric = postMetrics.find((current) => current.key === key);
      if (postMetric) {
        const { min, max } = postMetric;
        if (min !== undefined && !isEmpty(value) && value < min) {
          goalsErrors[key] = `Min is ${min}`;
          return;
        }
        if (max !== undefined && !isEmpty(value) && value > max) {
          goalsErrors[key] = `Max is ${max}`;
          return;
        }
      }
      const repOnMetricGoalError = this.validateRepOnMetricGoal({
        activity,
        activityDefinition,
        formErrors,
        index,
      });
      if (repOnMetricGoalError) {
        goalsErrors[key] = repOnMetricGoalError;
        return;
      }
    });
    if (Object.keys(goalsErrors).length > 0) {
      set(formErrors, `activities.${index}.goals`, goalsErrors);
    }
  }

  private validateActivities(
    activities: ActivityInput[],
    formErrors: FormErrors<TreatmentPlanInput>,
  ) {
    if (activities.length === 0) {
      set(formErrors, 'activities', 'Must add at least one activity');
    }
    activities.forEach((activity, index) => {
      const activityDefinition =
        this.activitiesDefinitions?.[activity.activityDefinitionId];
      if (!activityDefinition) {
        throw new Error(
          'cannot validate without activityDefinition: ' +
            activity.activityDefinitionId,
        );
      }
      const validateActivityProp: ValidateActivityProp = {
        activity,
        activityDefinition,
        formErrors,
        index,
      };
      this.validateActivityCategory(validateActivityProp);

      this.validateActivityReps(validateActivityProp);
      this.validateActivityDistance(validateActivityProp);
      this.validateActivityMinimumToConsiderRep(validateActivityProp);
      this.validateActivityInitialAngle(validateActivityProp);
      this.validateActivitySets(validateActivityProp);
      this.validateActivityHold(validateActivityProp);
      this.validateActivityRest(validateActivityProp);
      this.validateActivitySide(validateActivityProp);
      this.validateActivityDuration(validateActivityProp);
      this.validateActivityEquipment(validateActivityProp);
      this.validateActivityGoals(validateActivityProp);
    });
  }

  private validDate(
    startDate: string | undefined,
    formErrors: FormErrors<TreatmentPlanInput>,
  ) {
    if (this.validateAs === 'template') {
      return;
    }
    if (!!!startDate) {
      set(formErrors, 'startDate', REQUIRED);
    }
  }

  validate(treatmentPlanForm: TreatmentPlanInput) {
    if (!this.validateAs) {
      throw Error('validateAs must be set');
    }
    const formErrors: FormErrors<TreatmentPlanInput> = {};

    this.validateActivities(treatmentPlanForm.activities, formErrors);
    this.validDate(treatmentPlanForm.startDate, formErrors);
    this.validateName(
      treatmentPlanForm.name,
      treatmentPlanForm.id,
      formErrors,
      this.wasLastTreatmentPlanExecuted,
    );
    if (Object.keys(formErrors).length === 0) {
      return;
    }
    return formErrors;
  }
}

export function useValidation(mode: Mode, validAs: ValidateAs) {
  const treatmentPlanFormValidation =
    useMemo<TreatmentPlanFormValidation>(() => {
      return new TreatmentPlanFormValidation(mode, validAs);
    }, []);

  const [numberOfUpdates, setNumberOfUpdates] = useState<number>(0);

  const activityDefinitions = useSelector(getActivityDefinitionsSelector);
  const treatmentPlanTemplates = useSelector(getTreatmentPlanTemplatesSelector);
  const treatmentPlans = useSelector(getPatientTreatmentPlansSelector);
  const activitiesExecutions = useSelector(
    getPatientActivitiesExecutionsSelector,
  );
  const lastTreatmentPlanId = treatmentPlans?.[0]?.id;
  const wasLastTreatmentPlabExecuted = activitiesExecutions?.some(
    ({ treatmentPlanId }) => treatmentPlanId === lastTreatmentPlanId,
  );

  useEffect(() => {
    treatmentPlanFormValidation.setActivitiesDefinitions(activityDefinitions);
  }, [activityDefinitions]);

  useEffect(() => {
    treatmentPlanFormValidation.setTreatmentPlanTemplates(
      treatmentPlanTemplates,
    );
  }, [treatmentPlanTemplates]);

  useEffect(() => {
    treatmentPlanFormValidation.setTreatmentPlans(treatmentPlans);
  }, [treatmentPlans]);

  useEffect(() => {
    treatmentPlanFormValidation.setWasLastTreatmentPlanExecuted(
      wasLastTreatmentPlabExecuted,
    );
  }, [wasLastTreatmentPlabExecuted]);

  const validate = useMemo(() => {
    return treatmentPlanFormValidation.validate.bind(
      treatmentPlanFormValidation,
    );
  }, [treatmentPlanFormValidation, numberOfUpdates]);

  treatmentPlanFormValidation.onValidationDataChanged(() => {
    setNumberOfUpdates((currentNumberOfUpdates) => currentNumberOfUpdates + 1);
  });

  return { formValidation: treatmentPlanFormValidation, validate };
}

/** @jsxImportSource @emotion/react */

import { useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { getActivityDefinitionsSelector } from '../../../../state-manager/selectors/librarySelectors';
import ViewEyeIcon from '../../../../assets/viewEye.svg';
import AddCircleIcon from '../../../../assets/addCircle.svg';
import Chip from '../../../commons/Chip';
import AutomationLevelView from '../../../commons/AutomationLevelView';
import { CSSObject } from '@emotion/react';
import { fonts } from '../../../../style/fonts';
import { card, shapes } from '../../../../style/shapes';
import Loader from '../../../commons/Loader';
import ActivityThumbnail from '../../../commons/ActivityThumbnail';
import { ActivityDefinition } from '../../../../types/library';
import { SearchTextField } from '../../../commons/SearchTextField';
import { Dispatch, SetStateAction, useState } from 'react';
import ActivityDetails from '../commons/ActivityPreview';
import { buttons } from '../../../../style/buttons';
import ActivitySelectionFilter from './ActivitySelectionFilter';
import { filterBySelectedFilter } from './planCreationUtils';
import {
  LazyComponentProps,
  trackWindowScroll,
} from 'react-lazy-load-image-component';
import { breakpoints } from '../../../../style/breakpoints';
import { ellipsis } from '../../../../utils/cssUtils';

const activitySelectionContainerCss: CSSObject = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  gap: 20,

  '.filter-container': {
    display: 'flex',
    flexWrap: 'wrap-reverse',
    padding: '20px',
    paddingBottom: '0',

    [breakpoints.small]: {
      gap: '20px',
    },
    [breakpoints.large]: {
      gap: '54px',
    },
  },
};

const activitiesGridCss: CSSObject = {
  display: 'grid',
  overflowY: 'auto',
  overflowX: 'hidden',
  flex: 1,
  width: '100%',
  gridGap: '10px',

  [breakpoints.small]: {
    gridTemplateColumns: '160px 160px',
    gridGap: 10,
    padding: '0 9px',
  },

  [breakpoints.medium]: {
    gridTemplateColumns: '194px 194px',
    gridGap: 12,
    padding: '0 13px',
  },

  [breakpoints.large]: {
    gridTemplateColumns: '310px 310px',
    gridGap: 20,
    padding: '0 22px',
  },
};

const activityContainerCss: CSSObject = {
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '278px',

  [breakpoints.small]: {
    width: '160px',
    height: '258px',
  },

  [breakpoints.medium]: {
    width: '194px',
  },

  [breakpoints.large]: {
    width: '310px',
    padding: '20px',
  },

  '.header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: '100%',
    minHeight: '24px',
    height: '24px',
    marginBottom: '10px',

    '& > div': {
      display: 'flex',
      gap: '6px',

      [breakpoints.large]: {
        gap: '13px',
      },
    },
  },

  h2: {
    ...fonts.h2,
    margin: 0,
    ...ellipsis,
  },

  '.footer': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '8px',
    width: '100%',
    marginTop: '10px',

    [breakpoints.large]: {
      flexWrap: 'nowrap',
    },

    '.chip-container': {
      display: 'inline',
      ...ellipsis,
    },
  },
};

const activityViewModalCss: CSSObject = {
  '.MuiDialog-paper': {
    maxWidth: '1583px',
    height: '719px',
  },

  '.header': {
    display: 'flex',
    gap: '20px',
    borderBottom: shapes.border,
    padding: '30px',
  },

  '.header-title': {
    ...fonts.modalHeader,
  },

  '.header-info': {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },

  '.content': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 0,
  },

  '.footer': {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: shapes.border,
    padding: '20px',
  },
};

function predicate(
  activityDefinition: ActivityDefinition,
  searchTerm: string | undefined,
) {
  const fieldsToSearch = ['name', 'position', 'bodyParts'];
  const normalizedSearchTerm = searchTerm
    ? searchTerm.toLocaleLowerCase().replace(/[^a-z0-9]/g, '')
    : '';
  return fieldsToSearch.some((field) => {
    const fieldValue = activityDefinition[field as keyof ActivityDefinition];
    if (typeof fieldValue === 'string') {
      const normalizedFieldValue = fieldValue
        .toLocaleLowerCase()
        .replace(/[^a-z0-9]/g, '');
      return normalizedFieldValue.includes(normalizedSearchTerm);
    } else if (Array.isArray(fieldValue)) {
      return fieldValue.some(
        (item) =>
          typeof item === 'string' &&
          item
            .toLocaleLowerCase()
            .replace(/[^a-z0-9]/g, '')
            .includes(normalizedSearchTerm),
      );
    }
  });
}

function ActivityViewModal({
  activityDefinition,
  setActivityIdToView,
  onSelect,
}: {
  activityDefinition: ActivityDefinition;
  setActivityIdToView: Dispatch<SetStateAction<string>>;
  onSelect: (activityDefinition: ActivityDefinition) => void;
}) {
  return (
    <Dialog
      open={Boolean(activityDefinition)}
      onClose={() => setActivityIdToView('')}
      css={activityViewModalCss}
    >
      <DialogTitle className="header">
        <span className="header-title">{activityDefinition.name}</span>
        <div className="header-info">
          <Chip text={activityDefinition.position} />
          <Chip text={activityDefinition.plane} />
          <AutomationLevelView
            automationLevel={activityDefinition.automationLevel}
          />
        </div>
      </DialogTitle>
      <DialogContent className="content">
        <ActivityDetails activity={activityDefinition} />
        <div className="footer">
          <button
            css={{ ...buttons.secondary }}
            type="button"
            onClick={() => setActivityIdToView('')}
          >
            Cancel
          </button>
          <button
            css={{ ...buttons.primary }}
            type="button"
            onClick={() => {
              onSelect(activityDefinition);
              setActivityIdToView('');
            }}
          >
            Add Activity To Plan
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

interface ActivitySelectionProps extends LazyComponentProps {
  onSelect: (activityDefinition: ActivityDefinition) => void;
}

const ActivitySelection: React.FC<ActivitySelectionProps> = ({
  onSelect,
  scrollPosition,
}) => {
  const activityDefinitions = useSelector(getActivityDefinitionsSelector);
  const [searchTerm, onSearchTermChange] = useState<string | undefined>();
  const [activityIdToView, setActivityIdToView] = useState<string>('');
  const [selectedBodyPartFilters, setSelectedBodyPartFilters] = useState<
    string[]
  >(['All Body Parts']);
  const [selectedMonitoringTypeFilters, setSelectedMonitoringTypeFilters] =
    useState<string[]>(['All Monitoring Types']);

  if (!activityDefinitions) {
    return <Loader />;
  }

  const filteredActivityDefinitions = Object.values(activityDefinitions)
    // Filter by search term and filter out eng._phase: -1
    .filter((activityDefinition) => {
      return (
        activityDefinition.automationLevel !== -1 &&
        predicate(activityDefinition, searchTerm)
      );
    })
    .sort((a, b) => {
      const lowerCaseSearchTerm = searchTerm?.toLocaleLowerCase() ?? '';
      const aNameMatch = a.name
        .toLocaleLowerCase()
        .includes(lowerCaseSearchTerm)
        ? 1
        : 0;
      const bNameMatch = b.name
        .toLocaleLowerCase()
        .includes(lowerCaseSearchTerm)
        ? 1
        : 0;

      return bNameMatch - aNameMatch;
    })
    // Filter by selected dropdown filters
    .filter((activityDefinition) => {
      return filterBySelectedFilter(
        activityDefinition,
        selectedBodyPartFilters,
        selectedMonitoringTypeFilters,
      );
    });

  return (
    <div css={activitySelectionContainerCss}>
      <div className="filter-container">
        <ActivitySelectionFilter
          selectedBodyPartFilters={selectedBodyPartFilters}
          selectedMonitoringTypeFilters={selectedMonitoringTypeFilters}
          setSelectedBodyPartFilters={setSelectedBodyPartFilters}
          setSelectedMonitoringTypeFilters={setSelectedMonitoringTypeFilters}
        />
        <SearchTextField
          name={'activity'}
          enableAutocomplete
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          placeholder={`Search through ${
            Object.keys(filteredActivityDefinitions).length
          } activities...`}
          width={289}
        />
      </div>
      <div css={activitiesGridCss}>
        {filteredActivityDefinitions.map((activityDefinition) => {
          return (
            <div
              key={activityDefinition.id}
              css={{ ...card, ...activityContainerCss }}
            >
              <div className="header">
                <Tooltip title={activityDefinition.name}>
                  <h2>{activityDefinition.name}</h2>
                </Tooltip>
                <div>
                  <button
                    type="button"
                    value={activityDefinition.id}
                    onClick={(event) => {
                      setActivityIdToView(event.currentTarget.value);
                    }}
                  >
                    <ViewEyeIcon height="24" width="24" />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      onSelect(activityDefinition);
                    }}
                  >
                    <AddCircleIcon
                      height="24"
                      width="24"
                      aria-label="Add Activity"
                    />
                  </button>
                </div>
              </div>
              <ActivityThumbnail
                activityDefinition={activityDefinition}
                scrollPosition={scrollPosition}
                width={'100%'}
                height={'100%'}
              />
              <div className="footer">
                <Chip text={activityDefinition.position} />
                <Chip text={activityDefinition.plane} />
                <AutomationLevelView
                  automationLevel={activityDefinition.automationLevel}
                />
              </div>
            </div>
          );
        })}
      </div>
      {activityDefinitions[activityIdToView] && (
        <ActivityViewModal
          activityDefinition={activityDefinitions[activityIdToView]!}
          setActivityIdToView={setActivityIdToView}
          onSelect={onSelect}
        />
      )}
    </div>
  );
};

export default trackWindowScroll(ActivitySelection);

/** @jsxImportSource @emotion/react */

import { fonts } from '../../style/fonts';
import Chip from './Chip';

type AutomationLevelDetailsOption = {
  label: string;
  color: string;
  tooltip: string;
};
/* eslint-disable max-len */
const automationLevelDetailsOptions: AutomationLevelDetailsOption[] = [
  {
    label: 'CAPTURE',
    color: '#FF9900',
    tooltip:
      'Level 1: Capture Status--This exercise captures and displays the movements performed by patients. However, it does not enable setting or tracking patient goals.',
  },
  {
    label: 'TRACK',
    color: '#C97900',
    tooltip:
      'Level 2: Track Status--This exercise captures and tracks the repetition of exercises against predefined goals, which enables goal-setting and monitoring patients’ progress over time.',
  },
  {
    label: 'MEASURE',
    color: '#708541',
    tooltip:
      'Level 3:  Measure Status--This exercise captures, tracks, measures goals, and assesses high-level objectives, which enables a more detailed understanding of patient progress.',
  },
  {
    label: 'ASSESS',
    color: '#22AD00',
    tooltip:
      'Level 4: Assess Status--.This status includes all features, so that physical therapists can record, view, and track patient’s goals while also measuring specific metrics related to the exercises.',
  },
];

export default function AutomationLevelView({
  automationLevel,
  automationLabel,
}: {
  automationLevel?: number;
  automationLabel?: string;
}) {
  if (
    (automationLevel === undefined && automationLabel === undefined) ||
    (automationLevel !== undefined && automationLabel !== undefined)
  ) {
    // eslint-disable-next-line no-console
    console.error('pls give automationLevel **or** automationLabel');
    return <></>;
  }

  let automationLevelDetails: AutomationLevelDetailsOption | undefined;
  if (automationLevel !== undefined) {
    automationLevelDetails = automationLevelDetailsOptions[automationLevel];
  } else if (automationLabel !== undefined) {
    automationLevelDetails = automationLevelDetailsOptions.find(
      ({ label }) => label === automationLabel,
    );
  }
  if (!automationLevelDetails) {
    return <></>;
  }
  return (
    <Chip
      cssObject={{
        ...fonts.text,
        border: `1px solid ${automationLevelDetails.color}`,
        borderRadius: 4,
        color: automationLevelDetails.color,
        padding: '2px 8px 2px 8px',
        width: 'fit-content',
        backgroundColor: 'transparent',
      }}
      text={automationLevelDetails.label}
      tooltip={automationLevelDetails.tooltip}
    />
  );
}

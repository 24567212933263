export const minWidthArray: readonly [number, number, number, number] = [1920, 1200, 992, 100];

const buildQuery = (value: number) => `@media (min-width: ${value}px)`;

export type SizeLabel = 'extrasmall' | 'small' | 'medium' | 'large';

export const breakpoints: Record<SizeLabel, string> = {
  large: buildQuery(minWidthArray[0]),
  medium: buildQuery(minWidthArray[1]),
  small: buildQuery(minWidthArray[2]),
  extrasmall: buildQuery(minWidthArray[3]),
};

export const breakpointMap: Record<SizeLabel, number> = {
  large: minWidthArray[0],
  medium: minWidthArray[1],
  small: minWidthArray[2],
  extrasmall: minWidthArray[3],
};

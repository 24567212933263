/** @jsxImportSource @emotion/react */

import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { getUsersSelector } from '../../models/factories/userFactories';
import CreateUserModal from '../forms/create-user-form/CreateUserModal';
import { PatientFormMode } from '../forms/create-user-form/create-patient-form/patientFormTypes';
import PatientsTableView from './patients-table/PatientsTableView';
import { User } from '../../models/User';

type PatientsListProps = {
  nameSearchTerm: string | undefined;
  setNameSearchTerm: (name: string) => void;
  patientFormMode: PatientFormMode | undefined;
  setPatientFormMode: (patientFormMode: PatientFormMode | undefined) => void;
};

const getPatientsRowsData = createSelector(
  [getUsersSelector],
  (users): User[] => {
    return Object.values(users).filter((user) => user.isPatient());
  },
);

export default function PatientsList({
  nameSearchTerm,
  setNameSearchTerm,
  patientFormMode,
  setPatientFormMode,
}: PatientsListProps) {
  const history = useHistory();

  const patientsRowsData = useSelector(getPatientsRowsData);

  return (
    <div
      css={{
        display: 'flex',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <PatientsTableView
        patientsRowsData={patientsRowsData}
        onRowClick={(row: User) => {
          const searchParams = new URLSearchParams(history.location.search);
          searchParams.set('patient-id', row.id);
          searchParams.delete('case-id');
          history.push({
            pathname: '/patient',
            search: searchParams.toString(),
          });
        }}
        onViewPatientDetailsClick={(patient) => {
          setPatientFormMode({ mode: 'preview', initData: patient });
        }}
        onEditPatientDetailsClick={(patient) => {
          setPatientFormMode({ mode: 'edit', initData: patient });
        }}
        nameSearchTerm={nameSearchTerm}
      />
      <CreateUserModal
        type="patient"
        formMode={patientFormMode}
        setFormMode={setPatientFormMode}
        onCreateUser={setNameSearchTerm}
      />
    </div>
  );
}

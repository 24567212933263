import { v4 as uuid } from 'uuid';
import { ActivityInput, TreatmentPlanInput } from './treatmentPlanTypes';
import { ActivityDefinition } from '../../../../types/library';
import {
  CompareFn,
  comparisonRoutine,
  isEmpty,
} from '../../../../state-manager/utils/compare';
import { RadioInputFieldOption } from '../../../commons/form/forStateFrom';
import { tooltips } from '../commons/tooltips';
import { Activity } from '../../../../models/Activity';
import { TreatmentPlan } from '../../../../models/TreatmentPlan';
import { CSSObject } from '@emotion/react';
import { FormErrors } from '../../../commons/form/useForm';
import { useSelector } from 'react-redux';
import { getPatientActivitiesExecutionsSelector } from '../../../../models/ActivityExecution';
import { TreatmentPlanTemplate } from '../../../../models/TreatmentPlanTemplate';
import { toDatePickerFormat } from '../../../../utils/dateUtils';

function compareSide(
  nextCompareFn: CompareFn<ActivityInput> | undefined,
  a: ActivityInput,
  b: ActivityInput,
): number {
  const valA = a.side ?? '';
  const valB = b.side ?? '';
  return comparisonRoutine(a, b, valB, valA, nextCompareFn);
}

function comparePosition(
  nextCompareFn: CompareFn<ActivityInput> | undefined,
  activityDefinitions: Record<string, ActivityDefinition>,
  a: ActivityInput,
  b: ActivityInput,
): number {
  const getWight = (position?: string) => {
    switch (position) {
      case 'Standing':
        return 0;
      case 'Seated':
        return 1;
      case 'Supine':
        return 2;
      case 'Side-Lying':
        return 3;
      default:
        return 4;
    }
  };
  const valA = getWight(activityDefinitions[a.activityDefinitionId]?.position);
  const valB = getWight(activityDefinitions[b.activityDefinitionId]?.position);
  return comparisonRoutine(a, b, valA, valB, nextCompareFn);
}

export function orderActivitiesByPosition(
  activities: ActivityInput[],
  activityDefinitions: Record<string, ActivityDefinition>,
  order?: 'desc' | 'asc',
): ActivityInput[] {
  const secondCompare = compareSide.bind(undefined, undefined);
  const mainCompare = comparePosition.bind(undefined, secondCompare, activityDefinitions);

  // Compare function to sort positions alphabetically
  const compareByPositionAZ = (a: ActivityInput, b: ActivityInput): number => {
    const positionA = activityDefinitions[a.activityDefinitionId]?.position || '';
    const positionB = activityDefinitions[b.activityDefinitionId]?.position || '';

    return positionA.localeCompare(positionB);
  };

  const sortedActivities = [...activities].sort(mainCompare).sort(compareByPositionAZ);
  return order === 'desc' ? sortedActivities.reverse() : sortedActivities;
}

export function toggleSides(activities: ActivityInput[]): ActivityInput[] {
  return activities.map((activity) => {
    const side = activity.side;
    if (side === 'Left') {
      activity.side = 'Right';
    } else if (side === 'Right') {
      activity.side = 'Left';
    }
    return activity;
  });
}

export function createEmptyActivityInput(
  activityDefinition: ActivityDefinition,
): ActivityInput {
  const id = uuid();
  const { settings } = activityDefinition;

  const activityInput: ActivityInput = {
    id,
    goals: {},
    activityDefinitionId: activityDefinition.id,
  };
  if (settings.reps?.default !== undefined) {
    activityInput.reps = settings.reps.default;
  }
  if (!isEmpty(settings.minimum_to_consider_rep?.options.length)) {
    activityInput.minimumToConsiderRep = Number(settings.minimum_to_consider_rep?.options[0]);
  }
  if (!isEmpty(settings.initial_angle?.options.length)) {
    activityInput.init_angle_degrees = Number(settings.initial_angle?.options[0]);
  }
  if (settings.rest?.default !== undefined) {
    activityInput.rest = settings.rest.default;
  }
  if (settings.hold?.default !== undefined) {
    activityInput.hold = settings.hold.default;
  }
  if (settings.sets?.default !== undefined) {
    activityInput.sets = settings.sets.default;
  }
  if (settings.distance?.default !== undefined) {
    activityInput.distance = settings.distance.default;
  }
  if (!isEmpty(settings.duration?.default)) {
    activityInput.duration = settings.duration?.default;
  }
  if (settings.type?.options?.includes('Exercise')) {
    activityInput.type = ['Exercise'];
  } else if (settings.type?.options?.includes('Assessment')) {
    activityInput.type = ['Assessment'];
  }
  return activityInput;
}

export function copyToActivitiesInput(activities: Activity[]) {
  const activitiesInput: ActivityInput[] = [];
  activities.forEach((activity) => {
    const activityDefinition = activity.activityDefinition;
    if (activityDefinition) {
      activitiesInput.push({
        ...createEmptyActivityInput(activity.activityDefinition),
        ...activity,
      });
    }
  });
  return activitiesInput;
}

export function copyToTreatmentPlanInput(treatmentPlan: TreatmentPlan): TreatmentPlanInput {
  const today = new Date();
  const startDate = treatmentPlan.startDate;
  const treatmentPlanInput: TreatmentPlanInput = {
    id: uuid(),
    name: treatmentPlan.name,
    description: treatmentPlan.description,
    startDate: new Date(startDate) < today ? toDatePickerFormat(today) : startDate,
    activities: copyToActivitiesInput(treatmentPlan.activities),
  };
  return treatmentPlanInput;
}

export function copyTemplateToTreatmentPlanInput(
  template: TreatmentPlanTemplate,
): TreatmentPlanInput {
  const treatmentPlanInput: TreatmentPlanInput = {
    id: template.id ?? uuid(),
    name: template.name,
    description: template.description,
    startDate: toDatePickerFormat(new Date()),
    activities: copyToActivitiesInput(template.activities),
  };
  return treatmentPlanInput;
}

export const DEFAULT_NAME = 'Plan 1';

export function getCategoryOptions(activityDefinition: ActivityDefinition) {
  const categoryOptions: RadioInputFieldOption[] = [];
  const { settings } = activityDefinition;
  if (settings.type?.options.includes('Exercise')) {
    categoryOptions.push({ value: 'Exercise', tooltip: tooltips.exercise });
  }
  if (settings.type?.options.includes('Assessment')) {
    categoryOptions.push({ value: 'Assessment', tooltip: tooltips.assessment });
  }
  return categoryOptions;
}

export function markAccordionItemsAsError(
  formErrors: FormErrors<TreatmentPlanInput> | undefined,
): CSSObject {
  const markAccordionItemAsError = (index: number): CSSObject => {
    const selector: string = `div[data-rbd-draggable-id]:nth-of-type(${index + 1})`;
    return {
      [selector]: {
        'div.MuiAccordion-root': {
          borderColor: 'red',
        },
      },
    };
  };
  let markedItemsCss: CSSObject = {};
  if (!!formErrors?.activities && Array.isArray(formErrors?.activities)) {
    Object.keys(formErrors.activities).forEach((key) => {
      const markedItemCss = markAccordionItemAsError(Number(key));
      markedItemsCss = { ...markedItemsCss, ...markedItemCss };
    });
  }
  return {
    'div[data-rbd-droppable-id]': {
      ...markedItemsCss,
    },
  };
}

export const BODY_PARTS = [
  'Abdominals',
  'Ankles',
  'Back',
  'Balance',
  'Biceps',
  'Calf',
  'Cardio',
  'Cervical',
  'Chest',
  'Core',
  'DNF',
  'Elbows',
  'Foot',
  'Functional',
  'Functional Test',
  'Gait',
  'Hips',
  'Knees',
  'Lower Back',
  'Lumbar',
  'Lumbar Extensors',
  'Neck',
  'Rotator Cuff',
  'Shoulders',
  'Thoracic',
  'Total Body',
  'Triceps',
  'Upper Middle Back',
  'Wrists',
];

export const MONITORING_TYPES = ['Capture', 'Track', 'Measure', 'Assess'];

export const ALL_BODY_PARTS_OPTION = 'All Body Parts';
export const ALL_MONITORING_TYPES_OPTION = 'All Monitoring Types';

export function filterBySelectedFilter(
  activityDefinition: ActivityDefinition,
  selectedBodyPartFilters: string[],
  selectedMonitoringTypeFilters: string[],
) {
  if (!selectedBodyPartFilters.length || !selectedMonitoringTypeFilters.length) {
    return false;
  }

  if (
    selectedBodyPartFilters.includes(ALL_BODY_PARTS_OPTION) &&
    selectedMonitoringTypeFilters.includes(ALL_MONITORING_TYPES_OPTION)
  ) {
    return true;
  }

  if (selectedMonitoringTypeFilters.includes(ALL_MONITORING_TYPES_OPTION)) {
    return selectedBodyPartFilters.some((filter) => {
      return activityDefinition.bodyParts.includes(filter);
    });
  }

  if (selectedBodyPartFilters.includes(ALL_BODY_PARTS_OPTION)) {
    return selectedMonitoringTypeFilters.some((monitoringType) => {
      return activityDefinition.automationLevel === MONITORING_TYPES.indexOf(monitoringType);
    });
  }

  return selectedMonitoringTypeFilters.some((monitoringType) => {
    return selectedBodyPartFilters.some((bodyPart) => {
      return (
        activityDefinition.bodyParts.includes(bodyPart) &&
        activityDefinition.automationLevel === MONITORING_TYPES.indexOf(monitoringType)
      );
    });
  });
}

export function useIsPlanHasLocalResults(planId: string | null) {
  const activitiesExecutions = useSelector(getPatientActivitiesExecutionsSelector);

  const isPlanHasLocalResults = activitiesExecutions?.some(
    ({ treatmentPlanId }) => treatmentPlanId === planId,
  );

  return isPlanHasLocalResults;
}

export function createDefultPlanName(): string {
  const date = new Date();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `Plan ${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
}

/** @jsxImportSource @emotion/react */

import { Result } from '../../../models/ActivityExecution';
import { colors } from '../../../style/colors';
import { fonts } from '../../../style/fonts';
import { ImprovementDirections } from '../../../types/backendType';

export const NO_GOAL = 'no-goal';
export const MET_GOAL = 'met-goal';
export const NOT_MEET_GOAL = 'not-meet-goal';

export const legendOptions = {
  [MET_GOAL]: { label: 'Met Goal', color: '#22AD00' },
  [NOT_MEET_GOAL]: { label: 'Did Not Meet Goal', color: colors.red },
  [NO_GOAL]: { label: 'No Goal', color: '#000000' },
};

export function getGoalClassName(
  result:
    | Result
    | undefined
    | {
        value: number | undefined;
        goal: number | undefined;
        automationLevel: number | undefined;
      },
) {
  if (!result) {
    return;
  }

  if (result.automationLevel === 0) {
    return NO_GOAL;
  }

  if ('metGoal' in result) {
    if (result.metGoal) {
      return MET_GOAL;
    } else if (result.metGoal === false) {
      return NOT_MEET_GOAL;
    }
  }

  if (result.value === undefined || result.goal === undefined) {
    return NO_GOAL;
  }

  if ('improvementDirection' in result) {
    if (
      result.improvementDirection === ImprovementDirections.Goal &&
      result.value <= result.goal + (result.minClinicalSignificance ?? 0) &&
      result.value >= result.goal - (result.minClinicalSignificance ?? 0)
    ) {
      return MET_GOAL;
    }
  }

  //Keep as nonstric equality to match string values
  if (result.value == result.goal) {
    return MET_GOAL;
  }

  return NOT_MEET_GOAL;
}

export default function SessionLegend() {
  return (
    <div
      css={{ display: 'flex', gap: 20, ...fonts.largeLabel }}
      className="legend-container"
    >
      {Object.values(legendOptions).map(({ label, color }) => {
        return (
          <div
            css={{ display: 'flex', alignItems: 'center', gap: 10 }}
            key={label}
            className="legend-item"
          >
            <div css={{ width: 16, height: 16, backgroundColor: color }} />
            <div>{label}</div>
          </div>
        );
      })}
    </div>
  );
}

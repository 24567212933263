import { breakpointMap, SizeLabel } from '../style/breakpoints';
import useWindowDimensions from './useWindowDimensions';

// The hook will return true for all smaller screens
// Example: When screen size is 1920, useBreakpoints will return
// true if called with all current labels (large, medium and small)
export default function useBreakpoints(size: SizeLabel) {
  const [width] = useWindowDimensions();
  if (width && breakpointMap[size]) {
    return width >= breakpointMap[size];
  } else {
    return false;
  }
}

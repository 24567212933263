import { ActivityExecution } from '../models/ActivityExecution';

export const getVolumeMetricDisplayFormula = (volumeMetricKey: string): string => {
  if (volumeMetricKey == 'volume_band') {
    return 'band x sets x reps';
  } else if (volumeMetricKey == 'volume_weights') {
    return 'lbs x sets x reps';
  } else {
    return 'sets x reps';
  }
};

export const getBandVolumeDisplayBandType = (activity: ActivityExecution) => {
  const band = activity.activity?.equipment?.find((item) => item.Name == 'Band');
  return `${band?.Value} x `;
};

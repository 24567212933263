import { isEmpty } from '../state-manager/utils/compare';
import { UserDto } from '../types/backendType';

export type UserId = string;

export class User {
  private userDto: UserDto;

  constructor(userDto: UserDto) {
    this.userDto = userDto;
  }

  get role() {
    return this.userDto.user_info.role;
  }

  isPatient() {
    return this.role === 'patient';
  }

  isClinician() {
    return this.role === 'clinician';
  }

  get id() {
    return this.userDto.user_id;
  }

  get emrUserId() {
    return this.userDto.user_info.emr_user_id;
  }

  get firstName() {
    return this.userDto.user_info.name;
  }

  get lastName() {
    return this.userDto.user_info.last_name;
  }

  get middleName() {
    return this.userDto.user_info.middle_name;
  }

  isTestUser() {
    return (
      this.userDto.user_info.email.endsWith('@lakecarmel.com') ||
      this.userDto.user_info.additional_user_info?.is_test_user === true
    );
  }

  get name() {
    if (this.firstName && isEmpty(this.lastName)) {
      return this.firstName;
    }
    if (this.lastName && isEmpty(this.firstName)) {
      return this.lastName;
    }
    return `${this.firstName} ${this.lastName}`;
  }

  get creationDate() {
    return new Date(this.userDto.user_created_date);
  }

  get email() {
    return this.userDto.user_info.email;
  }

  get height() {
    return this.userDto.user_info.additional_user_info?.height;
  }

  get additionalInfo() {
    return this.userDto.user_info.additional_user_info;
  }

  get dateOfBirth() {
    const dateOfBirth = this.additionalInfo?.date_of_birth;
    if (isEmpty(dateOfBirth)) {
      return undefined;
    }
    if (dateOfBirth?.includes('T')) {
      return dateOfBirth.split('T')[0];
    }
    return dateOfBirth;
  }
}

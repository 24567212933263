/** @jsxImportSource @emotion/react */
import { CSSObject } from '@emotion/react';
import Dash from '../../assets/dash.svg';
import SortArrow from '../../assets/sortArrow.svg';
import { colors } from '../../style/colors';
import { Result } from '../../models/ActivityExecution';
import { isEmpty } from '../../state-manager/utils/compare';
import { ImprovementDirections } from '../../types/backendType';

const dashIconStyle: CSSObject = {
  color: colors.darkGrey,
  width: '17px',
  height: '4px',
};

export default function TrendIcon({
  resultsToCompare,
  height,
}: {
  resultsToCompare: (
    | Result
    | undefined
    | {
        value: number;
        improvementDirection: ImprovementDirections;
      }
  )[];
  height: number;
}) {
  if (resultsToCompare?.length === 0) {
    return <Dash css={{ ...dashIconStyle }} />;
  }

  const [previousResult, lastResult] = resultsToCompare;
  const lastImprovementDirection =
    lastResult?.improvementDirection?.toLowerCase();

  if (
    lastImprovementDirection &&
    !Object.values(ImprovementDirections).includes(
      lastImprovementDirection as ImprovementDirections,
    )
  ) {
    return <Dash css={{ ...dashIconStyle }} />;
  }

  const goalExists =
    lastResult &&
    'goal' in lastResult &&
    previousResult &&
    'goal' in previousResult;

  if (lastImprovementDirection === ImprovementDirections.Goal) {
    if (
      !goalExists ||
      isEmpty(lastResult.goal) ||
      isEmpty(previousResult.goal)
    ) {
      return <Dash css={{ ...dashIconStyle }} />;
    }
    const lastResultGoalDifference = lastResult.goal - lastResult.value;
    const previousResultGoalDifference =
      previousResult.goal - previousResult?.value;

    if (lastResultGoalDifference < previousResultGoalDifference) {
      return (
        <SortArrow
          css={{
            color: colors.green,
            height: `${height}px`,
            width: 'auto',
          }}
        />
      );
    } else {
      return (
        <SortArrow
          css={{
            transform: 'rotate(180deg)',
            color: colors.red,
            height: `${height}px`,
            width: 'auto',
          }}
        />
      );
    }
  } else if (
    lastImprovementDirection === ImprovementDirections.Up ||
    lastImprovementDirection === ImprovementDirections.Down
  ) {
    const valueDifference =
      previousResult && lastResult
        ? lastResult.value - previousResult.value
        : 0;

    if (valueDifference === 0) {
      return <Dash css={{ ...dashIconStyle }} />;
    }

    if (valueDifference > 0) {
      return (
        <SortArrow
          css={{
            color:
              lastImprovementDirection === ImprovementDirections.Up
                ? colors.green
                : lastImprovementDirection === ImprovementDirections.Down
                ? colors.red
                : colors.darkGrey,
            height: `${height}px`,
            width: 'auto',
          }}
        />
      );
    }
    return (
      <SortArrow
        css={{
          transform: 'rotate(180deg)',
          color:
            lastImprovementDirection === ImprovementDirections.Up
              ? colors.red
              : lastImprovementDirection === ImprovementDirections.Down
              ? colors.green
              : colors.darkGrey,
          height: `${height}px`,
          width: 'auto',
        }}
      />
    );
  }
  return <></>;
}

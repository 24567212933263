import { v4 as uuid } from 'uuid';

import { MovementDto, TreatmentPlanDto } from '../types/backendType';
import { UserComment } from '../types/comment';
import { ActivityDefinition } from '../types/library';
import { Activity } from './Activity';
import { ITreatmentPlanTemplate } from './TreatmentPlanTemplate';
import { toDatePickerFormat } from '../utils/dateUtils';

export type TreatmentPlanId = string;

export class TreatmentPlan implements ITreatmentPlanTemplate {
  private treatmentPlanDto: TreatmentPlanDto;

  private activityDefinitions: Record<string, ActivityDefinition> | undefined;

  id: TreatmentPlanId;

  name: string;

  assignorUserId: string;

  comments: UserComment[];

  startDate: string;

  assignedDate: Date;

  constructor({
    treatmentPlanDto,
    activityDefinitions,
  }: {
    treatmentPlanDto: TreatmentPlanDto;
    activityDefinitions?: Record<string, ActivityDefinition> | undefined;
  }) {
    this.treatmentPlanDto = treatmentPlanDto;
    this.activityDefinitions = activityDefinitions;
    this.id = treatmentPlanDto.treatment_plan_id;
    this.name = treatmentPlanDto.name;
    this.assignorUserId = treatmentPlanDto.assignor_user_id;
    this.comments = treatmentPlanDto.comments.map((comment) => {
      const userComment: UserComment = {
        id: uuid(),
        date: new Date(comment.date),
        assignorUserId: comment.assignor_user_id,
        comment: comment.comment,
      };
      return userComment;
    });
    this.startDate = toDatePickerFormat(new Date(treatmentPlanDto.start_date));
    this.assignedDate = new Date(treatmentPlanDto.assigned_date);
  }

  get activities() {
    return this.treatmentPlanDto.movements.map((movement) => {
      const movementDto: MovementDto = { ...movement };
      return new Activity({ movementDto, activityDefinitions: this.activityDefinitions });
    });
  }

  get description() {
    return this.treatmentPlanDto.description;
  }
}

import { PostMetricDto } from '../types/backendType';
import { ActivityDefinition } from '../types/library';

export class PostMetric {
  private postMetricDto: PostMetricDto;

  private static alreadyLogged: string[] = [];

  constructor({
    activityDefinition,
    key,
  }: {
    key: string;
    activityDefinition: ActivityDefinition;
  }) {
    const postMetricDto = key.startsWith('volume')
      ? activityDefinition.non_algo_pem?.find(({ metric_name }) => metric_name === key)
      : activityDefinition.postExerciseMetric?.find(({ metric_name }) => metric_name === key);

    if (!postMetricDto) {
      const errorMsg =
        `postMetric "${key}" not found in ` + `activityDefinition: ${activityDefinition.id}`;
      const errorMsgKey = `${key}_${activityDefinition.id}`;
      if (!PostMetric.alreadyLogged.includes(errorMsgKey)) {
        // eslint-disable-next-line no-console
        console.warn(errorMsg);
        PostMetric.alreadyLogged.push(errorMsgKey);
      }
      throw new Error(errorMsg);
    }
    this.postMetricDto = postMetricDto;
  }

  get key() {
    return this.postMetricDto.metric_name;
  }

  get name() {
    return this.postMetricDto.display_name;
  }

  get unit() {
    return this.postMetricDto.unit;
  }

  get metric_sd() {
    return this.postMetricDto.metric_sd;
  }

  get improvementDirection() {
    return this.postMetricDto.improvement_direction;
  }

  get libraryStandardDeviation() {
    return this.postMetricDto.metric_sd;
  }

  get math() {
    return this.postMetricDto['Metric math'];
  }

  get min() {
    return this.postMetricDto.min;
  }

  get graphType() {
    return this.postMetricDto.graph_type;
  }

  get max() {
    return this.postMetricDto.max;
  }

  get automationLevel() {
    return this.postMetricDto.metric_eng_phase;
  }

  get minClinicalSignificance() {
    return this.postMetricDto.metric_mcs;
  }
}

export function getPostMetrics(activityDefinition: ActivityDefinition) {
  if (!activityDefinition.postExerciseMetric) {
    return;
  }
  const postMetrics: PostMetric[] = [];
  activityDefinition.postExerciseMetric.forEach(({ metric_name }) => {
    try {
      const newPostMetric = new PostMetric({ key: metric_name, activityDefinition });
      if (newPostMetric) {
        postMetrics.push(newPostMetric);
      }
    } catch (e) {
      //error logged in PostMetric constructor
    }
  });
  return postMetrics;
}

import { User } from '../../../../models/User';
import { UserRole } from '../../../../types/backendType';
import { FormMode } from '../../../commons/form/formUtils';

export const genderOptions = ['Male', 'Female', 'Other'] as const;
export const ethnicityOptions = [
  'Caucasian',
  'African American',
  'Asian',
  'Native American',
  'Alaska Native',
  'Native Hawaiian or other Pacific Islander',
  'Hindi (India)',
  'Middle east',
  'Hispanic and Latino',
  'South American',
  'Other',
] as const;

export type PatientInput = {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  height: number;
  creationDate: Date;
  isTestUser: boolean;
  weight?: number | undefined;
  surgeryDate?: string | undefined;
  medicalCondition?: string | undefined;
  gender?: string | undefined;
  ethnicity?: string | undefined;
  surgicalLeg?: string | undefined;
  dateOfBirth?: string | undefined;
  role: UserRole;
};

export type PatientFormData = Partial<PatientInput> & {
  id: string;
  creationDate: Date;
  isTestUser: boolean;
  role: UserRole;
};

export const medicalConditionOptions = [
  'TKR/TKA',
  'Ligament repairs',
  'Meniscus repairs',
  'Knee scopes',
  'Quad/Patella Tendon repairs',
  'ORIF (open reduction internal fixation)',
  'Stem cell treatments',
  'Non-operative patients',
  'Other',
];

export const surgicalLegOptions = ['Left', 'Right', 'Both', 'None'];

export const editableFields: (keyof PatientInput)[] = [
  'height',
  'firstName',
  'middleName',
  'lastName',
  'weight',
  'surgeryDate',
  'medicalCondition',
  'gender',
  'ethnicity',
  'surgicalLeg',
  'dateOfBirth',
];

export type PatientFormMode =
  | FormMode<User>
  | { mode: 'newTestUser' }
  | { mode: 'new'; emrPatientId?: string };

type AdditionalUserInfoDto = {
  height?: number;
  weight?: number;
  gender?: string;
  surgery_date?: string;
  surgical_leg?: string;
  medical_condition?: string;
  ethnicity?: string;
  is_test_user?: boolean;
  date_of_birth?: string;
  prefix?: string;
};

const USER_ROLL = ['patient', 'clinician', 'admin'] as const;
export type UserRole = (typeof USER_ROLL)[number];

export function isUserRole(str: string): str is UserRole {
  return USER_ROLL.includes(str as UserRole);
}

export type UserInfoDto = {
  email: string;
  role: UserRole;
  name: string;
  middle_name?: string;
  last_name: string;
  additional_user_info?: AdditionalUserInfoDto;
  emr_user_id?: string;
};

export type UpdateUserFormDto = {
  role: string;
  name: string;
  last_name: string;
  additional_user_info?: AdditionalUserInfoDto;
};

export type UserDto = {
  user_id: string;
  user_info: UserInfoDto;
  user_created_date: string;
};

export type CaseDto = {
  case_id: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  additional_info: {
    body_parts: string[];
  };
};

type CommentDto = {
  assignor_user_id: string;
  date: string;
  comment: string;
};

export type TreatmentPlanDto = {
  treatment_plan_id: string;
  case_id: string;
  name: string;
  description: string;
  assigned_date: string;
  assignor_user_id: string;
  start_date: string;
  end_date: string;
  comments: CommentDto[];
  movements: MovementDto[];
};

export type TreatmentPlanInputDto = {
  name: string;
  description: string;
  assignor_user_id: string;
  start_date: string;
  end_date?: string;
  comments: CommentDto[];
  movements: (MovementInputDto & { plan_index: number })[];
  state: '';
  case_id: string;
};

export type TreatmentPlanTemplateDto = {
  treatment_plan_id: string;
  name: string;
  description: string;
  movements: MovementDto[];
};

export type TreatmentPlanTemplateInputDto = {
  name: string;
  description: string;
  movements: (MovementInputDto & { plan_index: number })[];
  state: '';
};

const TIME_UNITS = ['minutes', 'seconds', 'hours'] as const;

const UNIT_TYPE = [
  ...TIME_UNITS,
  'degrees',
  'millimeters',
  'centimeter',
  'meters',
  'kilogram',
  'none',
] as const;

type UnitType = (typeof UNIT_TYPE)[number];

export type ResultsDto = {
  key: string;
  value: number;
  unit: UnitType;
};

export type MovementResultsDto = {
  treatment_plan_id: string;
  movement_id: string;
  movement_details?: {
    movement_id: string;
    movement_info?: MovementBaseDto &
      MovementInputData & { settings: MovementInfoSetting } & { supported?: string };
  };
  session_id: string;
  sub_session_id: string;
  stop_date: string;
  start_date: string;
  plan_index: number;
  status: string;
  status_description: string;
  movement_completion_rate: number;
  results_data?: {
    sets_info?: { set: number; reps: number; hold: number; duration: number }[];
    summary?: {
      phase_eng: number[];
      num_of_sets?: number;
      post_metrics_array?: {
        metric_name: string;
        metric_data: {
          side: string;
          value?: number;
          unit: string;
          metric_sd?: number | 'NA';
          metric_mcs?: number;
        };
        metric_stats?: {
          std?: number;
          mean?: number;
          median?: number;
          max_value_frame?: number;
        };
      }[];
    };
  };
};

export type MovementResultsOutputDto = {
  movements: MovementResultsDto[];
};

export type MovementMediaDto = {
  mediaLink: string;
  expiresInSec: number;
};

type ErrorResponseBody = {
  title: string;
  details: string;
};

export function isErrorResponseBody(data: Object | undefined): data is ErrorResponseBody {
  if (!data) {
    return false;
  }
  return 'title' in data && 'details' in data;
}

export type RealtimeMetric = {
  metric_name: string;
  unit: string;
  max_range?: number;
  min_range?: number;
};

export type PostMetricDto = {
  metric_name: string;
  display_name: string;
  unit: string;
  improvement_direction: ImprovementDirections;
  graph_type: 'time' | 'scatter' | 'NA';
  'Metric math': 'min' | 'max' | 'average' | 'NA';
  min?: number;
  max?: number;
  metric_sd: number | 'NA';
  metric_mcs: number;
  metric_eng_phase: number;
};

export type MovementBaseDto = {
  exercise_name: string;
  position?: string;
  plane?: string;
  description?: string;
  body_parts?: string[];
  post_exercise_metric?: PostMetricDto[];
  realtime_metric?: RealtimeMetric[];
  //Old required equipment array
  assistant_device_needed?: string[];
  // New required equipment array
  assistant_equipment_needed?: Equipment[];
  // New optional equipment array
  assistant_equipment_optional?: Equipment[];
  'eng._phase'?: number;
  rep_on?: string[];
  category: string[];
  end_condition: EndCondition;
  time_per_meter?: string;
  non_algo_pem?: PostMetricDto[];
};

export type EndCondition =
  | 'reps'
  | 'reps_non_algo'
  | 'time'
  | 'total_distance'
  | 'position'
  | 'guided_rep_non_algo';

export const DONT_CARE = 'Dont Care';
export type OptionsSetting = {
  options: string[];
};

export type NumericOptionsSetting = {
  options: string[];
};

export type OptionsExpressionSetting = {
  options_expression: string;
};

export type NumericSetting = {
  min?: number;
  max?: number;
  step_size?: number;
  default?: number;
};

export type MovementDefinitionDto = {
  movement_id: string;
  movement_info: MovementBaseDto & { settings: MovementInfoSetting };
};

type MovementInputData = {
  type?: string[];
  side?: string;
  load?: { unit: string; value: string };
  sets?: number;
  reps?: number;
  minimumToConsiderRep?: number;
  hold?: number;
  rest?: number;
  distance?: number;
  duration?: number;
  // Old optional equipment array
  assistant_device_optional?: string[];
  // New all-inclusive equipment array
  equipment?: Equipment[];
  goals?: { metric_name: string; value: number }[];
  comments?: string;
  require_patient_acknowledgment?: boolean;
  init_angle_degrees?: number;
};

export type MovementInputDto = {
  movement_id: string;
  movement_info: MovementInputData;
};

export type MovementDto = {
  movement_id: string;
  movement_info: MovementBaseDto & MovementInputData;
  plan_index?: number;
};

export type MovementInfoSetting = {
  type?: OptionsSetting;
  side?: OptionsSetting;
  load?: OptionsSetting;
  sets?: NumericSetting;
  reps?: NumericSetting;
  minimum_to_consider_rep?: NumericOptionsSetting;
  initial_angle?: NumericOptionsSetting;
  hold?: NumericSetting;
  rest?: NumericSetting;
  distance?: NumericSetting;
  duration?: NumericSetting;
  assistant_device_optional?: OptionsExpressionSetting;
  rate?: { default: number; max: number; min: number; unit: string };
};

export type Equipment = {
  Units?: string;
  Default?: string;
  Min?: number;
  Max?: number;
  Name: string;
  Group: number;
  Options?: string[];
};

export type MovementExecutionMetricResults = {
  metric_name: string;
  metric_data: {
    side: string;
    value: number[];
  };
};

export type Expression = { metric: string; side: string } | { operator: string };

export type QualitativeOptional = {
  expression: Expression[];
  violation_time: number;
  upper_range: number;
  lower_range: number;
  message_id: number;
};

export type DaaOptionalData = {
  index: number;
  message_id: number;
  violation: string;
};

export type MovementExecutionMetricsDTO = {
  overtime_metrics: MovementExecutionMetricResults[];
  qualitative_overtime_metrics?: (number[] | null)[];
  metadata?: {
    video_delta_frames: number;
    qualitative_optional_list?: QualitativeOptional[];
    daa_optional_list?: DaaOptionalData[];
  };
  version?: number;
};

export type TranscriptSessionDto = {
  transcript_media_upload: {
    mediaLink: string;
    expiresInSec: number;
  };
  transcript_session_status: {
    session_id: string;
    transcript_status: string;
  };
};

export type TranscriptOutputDto = {
  // transcript_session_output?: {
  //   status: string;
  //   results: {
  //     transcripts: { transcript: string }[];
  //   };
  // };
  transcript_session_output?: string;
  transcript_session_status: {
    session_id: string;
    transcript_status: string;
  };
};

export type AssessmentProcessingDTO = {
  session_id: string;
  summary_status: 'Processing';
};

export type AssessmentOutputDTO = {
  summary_session_status: {
    session_id: string;
    summary_status: 'Processing' | 'Completed';
  };
  summary_session_output: string;
};

export type PatientPrivacySetting = {
  agree: boolean;
  consent_date: string | undefined;
  document_id: string;
  document_version: string | undefined;
};

export enum ImprovementDirections {
  Up = 'up',
  Down = 'down',
  Goal = 'goal',
}

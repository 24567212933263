/** @jsxImportSource @emotion/react */

import { useEffect, useMemo, useState } from 'react';
import { isEmpty, min } from 'lodash-es';

import { fetchMovementExecutionMetricsUrl } from '../../../services/treatmentPlanService';
import { useSelector } from 'react-redux';
import {
  getCurrentPatientIdSelector,
  getMeasurementSystemSelector,
} from '../../../state-manager/selectors/appSelectors';
import MetricResultsGraph, {
  ExtendedPoint,
} from '../../commons/MetricResultsGraph';
import { ActivityExecution } from '../../../models/ActivityExecution';
import Loader from '../../commons/Loader';
import Select, { SelectOption } from '../../commons/form/Select';
import { MovementExecutionMetricsDTO } from '../../../types/backendType';
import { OverTimeResults } from '../../../models/OverTimeResults';
import { QualitativeOverTimeResults } from '../../../models/QualitativeOverTimeResults';
import { getMeasurementSystemUnit } from '../../../utils/unitUtils';

const FRAME_PER_SECOND = 30;

interface ExtendedSelectOption extends SelectOption<string> {
  unit: string;
}

export default function OverTimeMetricsGraph({
  graphCursorPosition,
  setGraphCursorPosition,
  onGraphClick,
  activityExecution,
}: {
  graphCursorPosition: number | undefined;
  setGraphCursorPosition: (cursorPosition: number | undefined) => void;
  onGraphClick: (point: ExtendedPoint) => void;
  activityExecution: ActivityExecution | undefined;
}) {
  const patientId = useSelector(getCurrentPatientIdSelector);
  const [movementExecutionMetricsDTO, setMovementExecutionMetricsDTO] =
    useState<MovementExecutionMetricsDTO | undefined>();
  const [overTimeResults, setOverTimeResults] = useState<
    OverTimeResults | undefined
  >();
  const [qualitativeOverTimeResults, setQualitativeOverTimeResults] = useState<
    QualitativeOverTimeResults | undefined
  >();
  const [isRequestFinished, setIsRequestFinished] = useState<
    boolean | undefined
  >();

  const measurementSystem = useSelector(getMeasurementSystemSelector);

  const metricsOptions = useMemo(() => {
    if (overTimeResults) {
      const options: ExtendedSelectOption[] = [];
      overTimeResults?.allActivityResults.forEach((metricResult) => {
        if (metricResult.automationLevel !== 0) {
          const measurementSystemUnit = getMeasurementSystemUnit(
            metricResult.unit,
            measurementSystem,
          );

          if (
            activityExecution?.getMetric(metricResult.metricKey)?.graphType ===
            'NA'
          ) {
            return;
          }

          options.push({
            label: overTimeResults.getFormattedMetricLabel(metricResult),
            value: metricResult.metricKey,
            unit: measurementSystemUnit || '',
          });
        }
      });
      return options.filter((option): option is ExtendedSelectOption =>
        Boolean(option),
      );
    }
  }, [overTimeResults]);

  const [selectedMetrics, setSelectedMetrics] = useState<
    string[] | undefined
  >();

  const disabledMetricsOptions = useMemo(() => {
    if (metricsOptions?.length) {
      return metricsOptions?.filter((option) => {
        const selectedMetricOption = metricsOptions?.find(
          (metricOption) => metricOption?.value === selectedMetrics?.[0],
        );
        return (
          selectedMetricOption && selectedMetricOption.unit !== option?.unit
        );
      });
    }
    return [];
  }, [selectedMetrics, metricsOptions]);

  useEffect(() => {
    if (!movementExecutionMetricsDTO?.overtime_metrics || !activityExecution) {
      return;
    }
    const newOverTimeResults = new OverTimeResults(
      movementExecutionMetricsDTO.overtime_metrics,
      activityExecution,
    );
    setOverTimeResults(newOverTimeResults);

    if (
      movementExecutionMetricsDTO.qualitative_overtime_metrics &&
      movementExecutionMetricsDTO.metadata?.qualitative_optional_list
    ) {
      const newQualitativeOverTimeResults = new QualitativeOverTimeResults(
        movementExecutionMetricsDTO.qualitative_overtime_metrics,
        movementExecutionMetricsDTO.metadata.qualitative_optional_list,
        movementExecutionMetricsDTO.metadata.daa_optional_list,
      );
      setQualitativeOverTimeResults(newQualitativeOverTimeResults);
    }
  }, [movementExecutionMetricsDTO, activityExecution]);

  useEffect(() => {
    if (!patientId || !activityExecution) {
      return;
    }
    const sessionId = activityExecution.sessionId;
    const subSessionId = activityExecution.subSessionId;

    fetchMovementExecutionMetricsUrl({
      patientId,
      sessionId,
      subSessionId,
      version: 2,
    })
      .then((url) => {
        if (url) {
          return fetch(url)
            .then((response) => response.text())
            .then((text) => {
              const sanitizedText = text.replace(/NaN/g, 'null');
              return JSON.parse(sanitizedText);
            })
            .then((results: MovementExecutionMetricsDTO) => {
              setMovementExecutionMetricsDTO(results);
            });
        }
      })
      .catch(() => {
        // Fallback to old API
        fetchMovementExecutionMetricsUrl({
          patientId,
          sessionId,
          subSessionId,
        })
          .then((url) => {
            if (url) {
              return fetch(url)
                .then((response) => response.text())
                .then((text) => {
                  const sanitizedText = text.replace(/NaN/g, 'null');
                  return JSON.parse(sanitizedText);
                })
                .then((results: MovementExecutionMetricsDTO) => {
                  if (Array.isArray(results)) {
                    const newDTO = {
                      overtime_metrics: results,
                    };
                    setMovementExecutionMetricsDTO(newDTO);
                  }
                });
            }
          })
          .catch(() => {
            // eslint-disable-next-line no-console
            console.error('failed to fetch MovementExecutionMetricsUrl');
          });
      })
      .finally(() => {
        setIsRequestFinished(true);
      });
  }, [activityExecution, patientId]);

  useEffect(() => {
    if (metricsOptions?.[0]) {
      setSelectedMetrics([metricsOptions[0].value]);
    }
  }, [metricsOptions]);

  const lowestResult = useMemo(() => {
    return min(overTimeResults?.allActivityResults[0]?.value);
  }, [overTimeResults]);

  if (
    (isRequestFinished && isEmpty(overTimeResults)) ||
    (metricsOptions?.length === 0 && !selectedMetrics)
  ) {
    return (
      <div
        className="graph-view"
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        no data found
      </div>
    );
  }

  let extendedPoints: ExtendedPoint[] | undefined;
  if (overTimeResults && Boolean(overTimeResults.allActivityResults.length)) {
    const results = overTimeResults.allActivityResults[0]?.value;
    if (results) {
      extendedPoints = results.map((_, index) => {
        return { time: index * (1000 / FRAME_PER_SECOND) * 0.001 };
      });
      overTimeResults.allActivityResults?.map((metricResults) => {
        metricResults.value.forEach((result, index) => {
          if (extendedPoints) {
            const point = extendedPoints[index];
            if (point) {
              point[metricResults.metricKey] = result;
              const qualitativeResult =
                qualitativeOverTimeResults?.allQualitativeResults?.[index];
              if (qualitativeResult && qualitativeResult.length > 0) {
                point.qualitative = lowestResult ?? 0;
              }
            }
          }
        });
      });
    }
  }

  return (
    <div
      className="graph-view"
      css={{ position: 'relative', '.select-input': { width: '100%' } }}
    >
      {metricsOptions && selectedMetrics && (
        <Select
          name="metric"
          value={selectedMetrics}
          options={metricsOptions}
          onChange={(value) => setSelectedMetrics(value)}
          disabledOptions={disabledMetricsOptions}
        />
      )}
      <div
        css={{
          height: 'calc(100% - 40px)',
          padding: '2px 20px 2px 2px',
        }}
      >
        {extendedPoints && overTimeResults && (
          <MetricResultsGraph
            onGraphClick={onGraphClick}
            cursorPosition={graphCursorPosition}
            setCursorPosition={setGraphCursorPosition}
            activityExecution={activityExecution}
            extendedPoints={extendedPoints}
            selectedMetrics={selectedMetrics}
            overTimeResults={overTimeResults}
            qualitativeData={qualitativeOverTimeResults}
            measurementSystem={measurementSystem}
          />
        )}
      </div>
      {!isRequestFinished && <Loader />}
    </div>
  );
}
